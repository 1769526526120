import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';
import {
  Dropdown,
  Header,
  Popup,
  Icon,
  Input as SemanticInput,
} from 'semantic-ui-react';
import cn from 'classnames';

import { Button, Loading } from 'components';
import { Planning, Portfolio, Storage } from 'actions';
import { colors } from 'utils/colors';
import { filterOutKeysFromObject } from 'utils/helpers';
import {
  SpendingTools,
  CppModal,
  OasModal,
  NavigationButtons,
} from '../components';
import numSubpagesInEachSection from '../numSubPages';
import DeleteWarningModal from '../components/DeleteWarningModal';
import { isGlobeInvestor } from '../../../../../utils/helpers';

@withRouter
class PersonalDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      warning: {},

      isSpendingModalOpen: false,
      isOasModalOpen: false,
      isCppModalOpen: false,
      ableToGoNext: true,
      isCouple: false,
      deleteWarningModalOpen: false,
      numSubPages: numSubpagesInEachSection.personalDetails,
    };
  }

  componentWillMount() {
    const {
      dispatch,
      data,
      modelPortfolios,
      history,
      match,
      cachePB,
      cacheTW,
      cacheWR,
      cacheTS,
      cacheSR,
      blueprintDeleteWarningSuppress,
    } = this.props || {};
    const { numSubPages } = this.state;

    if (!data || data.personalDetails === undefined) {
      return history.push(`/planning/blueprint/${match.params.id}/overview`);
    }

    const { spending_amount, selectedSpendingTool, currentSubPage } =
      data.personalDetails || {};

    if (!modelPortfolios.length) {
      dispatch(Portfolio.fetchModel());
    }

    const modifiedPersonalDetailsData = {
      // eslint-disable-next-line radix
      spending_amount: spending_amount || 0,
      selectedSpendingTool: selectedSpendingTool || 'manual',
      currentSubPage: currentSubPage
        ? Math.min(numSubPages, currentSubPage)
        : 1,
    };
    dispatch(
      Planning.updateBlueprintData(
        'personalDetails',
        modifiedPersonalDetailsData
      )
    );

    // Check is something has been changed at following steps
    // if so, chche it and set it back when click Reculate&Next btn
    if (!cachePB) {
      dispatch(Storage.setItem('cachePB', data.personalDetails));
    }

    if (!cacheTW) {
      dispatch(Storage.setItem('cacheTW', data.targetWealth));
    }

    if (!cacheWR) {
      dispatch(Storage.setItem('cacheWR', data.wealthResult));
    }

    if (!cacheTS) {
      dispatch(Storage.setItem('cacheTS', data.targetSaving));
    }

    if (!cacheSR) {
      dispatch(Storage.setItem('cacheSR', data.savingResult));
    }

    // ensure all *completed* sections that the user is not actively clicked on
    // are on page number numSubPages[<section>] + 1 (to display the full red progress bar)
    dispatch(
      Planning.updateCompletedSectionsPageNumbers('personalDetails', {
        ...data, // ensure we are using most recent data (store might not update in time)
        personalDetails: {
          ...data.personalDetails,
          ...modifiedPersonalDetailsData,
        },
      })
    );

    // user must have made edits to subsequent sections
    if (data.targetWealth && data.targetWealth.currentSubPage >= 1) {
      if (!blueprintDeleteWarningSuppress) {
        this.setDeleteWarningModalVisibility(true);
      }
    }

    return dispatch(Planning.getCppOasDataset());
  }

  componentDidUpdate(prevProp) {
    const { data } = this.props;
    const { ableToGoNext } = this.state;

    if (prevProp.data && data) {
      const excludedKeys = [
        'currentSubPage',
        'seenConfirmModal',
        'selectedSpendingTool',
        'spendingToolData',
      ];
      const prev = filterOutKeysFromObject(
        prevProp.data.personalDetails,
        excludedKeys
      );
      const curr = filterOutKeysFromObject(data.personalDetails, excludedKeys);
      if (
        JSON.stringify(prev, Object.keys(prev).sort()) !==
        JSON.stringify(curr, Object.keys(prev).sort())
      ) {
        this.clearFollowingSection();
        // make the message on the last page that prevents users from going to the 'target wealth' disappear
        // ie. 'Since you are expecting to receive more income in retirement...'
        if (!ableToGoNext) this.setState({ ableToGoNext: true });
      }
    }
  }

  componentWillUnmount() {
    const { dispatch, history } = this.props;

    if (!history.location.pathname.includes('overview')) {
      dispatch(Storage.removeItem('cachePB'));
    }
  }

  getProvinces = () => [
    { key: 'o', text: 'Ontario', value: 'Ontario' },
    { key: 'bc', text: 'British Columbia', value: 'British Columbia' },
    { key: 'q', text: 'Quebec', value: 'Quebec' },
    { key: 'a', text: 'Alberta', value: 'Alberta' },
    { key: 'ns', text: 'Nova Scotia', value: 'Nova Scotia' },
    { key: 'l', text: 'Newfoundland', value: 'Newfoundland & Labrador' },
    { key: 's', text: 'Saskatchewan', value: 'Saskatchewan' },
    { key: 'm', text: 'Manitoba', value: 'Manitoba' },
    { key: 'nb', text: 'New Brunswick', value: 'New Brunswick' },
    { key: 'pe', text: 'Prince Edward Island', value: 'Prince Edward Island' },
  ];

  setTargetWealth = (isNewBlueprint) => {
    const { dispatch, history, match, cacheTW, cachePB, cacheWR, user_region } =
      this.props;

    if (isNewBlueprint) {
      dispatch(Planning.updateBlueprintData('targetWealth', {}));
    } else {
      const { modelPortfolios } = this.props;
      const { wealth_portfolio, annuityRate } = cacheTW || {};
      const selectedPortfolio = modelPortfolios.find(
        (port) => Number(port.id) === Number(wealth_portfolio)
      );
      if (!selectedPortfolio) {
        dispatch(Planning.updateBlueprintData('targetWealth', {}));
      } else if (wealth_portfolio && selectedPortfolio === undefined) {
        dispatch(Planning.clearBlueprintSection('targetWealth'));
        dispatch(Planning.clearBlueprintSection('wealthResult'));
        dispatch(Planning.updateBlueprintData('targetWealth', {}));
      } else {
        const { data } = this.props;
        const { is_couple_plan } = data.retirementBlueprint || {};
        if (JSON.stringify(data.personalDetails) !== JSON.stringify(cachePB)) {
          const {
            gender,
            cpp,
            cppAge,
            oas,
            oasAge,
            age,
            retAge,
            pension,
            other,
            spending_amount,
            gender2,
            cpp2,
            cppAge2,
            oas2,
            oasAge2,
            age2,
            retAge2,
            pension2,
            other2,
            wage,
            wage2,
            declinePattern,
          } = data.personalDetails || {};

          const spouseParams =
            is_couple_plan === 'true'
              ? {
                  age2,
                  retAge2,
                  gender2,
                  cpp2,
                  cppAge2,
                  oas2,
                  oasAge2,
                  pension2,
                  other2,
                  wage2: wage2 || 0,
                  ...(pension2 && { pensionAge2: retAge2 }),
                  ...(pension2 && { pensionInf2: false }),
                  ...(other2 && { otherAge2: retAge2 }),
                  ...(other2 && { otherInf2: false }),
                  ...(declinePattern && {
                    declinePattern2: {
                      percent: declinePattern.percent,
                      duration: declinePattern.duration,
                    },
                  }),
                }
              : {};
          const params = {
            spouse: is_couple_plan === 'true',
            exp: spending_amount,
            gender,
            cpp,
            cppAge,
            oas,
            oasAge,
            age,
            retAge,
            pension,
            other,
            wage: wage || 0,
            ...(pension && { pensionAge: retAge }),
            ...(pension && { pensionInf: false }),
            ...(other && { otherAge: retAge }),
            ...(other && { otherInf: false }),
            ...(declinePattern && { declinePattern }),
            ...spouseParams,
          };

          if (annuityRate) {
            return dispatch(
              Planning.analyzeBlueprintWealth({
                ...params,
                region: user_region,
                annuityRate: annuityRate / 100,
              })
            ).then((wealthResult) => {
              dispatch(
                Planning.updateBlueprintData('targetWealth', {
                  ...cacheTW,
                  targ: wealthResult.result.Annuity,
                })
              );
              dispatch(
                Planning.updateBlueprintData(
                  'wealthResult',
                  wealthResult.result
                )
              );

              return history.push(
                `/planning/blueprint/${match.params.id}/target_wealth`
              );
            });
          }

          return dispatch(
            Planning.analyzeBlueprintWealth({
              ...params,
              symbols: selectedPortfolio.symbols,
              weights: selectedPortfolio.weights,
              region: selectedPortfolio.region,
              gic_info: selectedPortfolio.gic_info,
            })
          ).then((wealthResult) => {
            const updateCacheTW = {
              ...cacheTW,
              targ: wealthResult.result[
                Object.keys(cacheWR).find(
                  (percentage) =>
                    cacheTW && cacheWR[percentage] === cacheTW.targ
                )
              ],
            };

            dispatch(
              Planning.updateBlueprintData('targetWealth', {
                ...updateCacheTW,
              })
            );
            dispatch(
              Planning.updateBlueprintData('wealthResult', wealthResult.result)
            );

            return history.push(
              `/planning/blueprint/${match.params.id}/target_wealth`
            );
          });
        }
      }
    }

    return history.push(`/planning/blueprint/${match.params.id}/target_wealth`);
  };

  setDeleteWarningModalVisibility = (isOpen) =>
    this.setState({ deleteWarningModalOpen: isOpen });

  clearFollowingSection = () => {
    const { dispatch } = this.props;

    dispatch(Planning.clearBlueprintSection('targetWealth'));
    dispatch(Planning.clearBlueprintSection('wealthResult'));
    // TS values no longer need to be cleared if other values are modified.
    // dispatch(Planning.clearBlueprintSection('targetSaving'));
    dispatch(Planning.clearBlueprintSection('savingResult'));
    dispatch(Planning.clearBlueprintSection('summary'));
  };

  handleDeclinePatternChange = (e, declinePattern) => {
    const { name, value } = e.target;
    const { cacheInputChanged, dispatch } = this.props;
    const { errors } = this.state;
    const limitRegex = /^[0-9]{1,9}$/;
    if (value !== '' && (!limitRegex.test(value) || Number(value) === 0))
      return;
    const num = value === '' ? undefined : value > 100 ? 100 : Number(value);

    dispatch(
      Planning.updateBlueprintData('personalDetails', {
        declinePattern: {
          ...declinePattern,
          [name]: num,
        },
      })
    );

    this.setState({
      ableToGoNext: true,
      errors: { ...errors, [name]: null },
    });

    if (!cacheInputChanged) {
      dispatch(Storage.setItem('cacheInputChanged', true));
      dispatch(Storage.setItem('cachePDChanged', true));
    }
  };

  updateCppInfo = (cppAge, cpp) => {
    const { isCouple, errors } = this.state;
    const { dispatch } = this.props;

    if (isCouple) {
      dispatch(
        Planning.updateBlueprintData('personalDetails', {
          cppAge2: cppAge,
          cpp2: cpp,
        })
      );

      this.setState({
        ableToGoNext: true,
        errors: { ...errors, cppAge2: null, cpp2: null },
      });
    } else {
      dispatch(
        Planning.updateBlueprintData('personalDetails', { cppAge, cpp })
      );

      this.setState({
        ableToGoNext: true,
        errors: { ...errors, cppAge: null, cpp: null },
      });
    }
  };

  updateOasInfo = (oasAge, oas) => {
    const { isCouple, errors } = this.state;
    const { dispatch } = this.props;

    if (isCouple) {
      dispatch(
        Planning.updateBlueprintData('personalDetails', {
          oasAge2: oasAge,
          oas2: oas,
        })
      );

      this.setState({
        ableToGoNext: true,
        errors: { ...errors, oasAge2: null, oas2: null },
      });
    } else {
      dispatch(
        Planning.updateBlueprintData('personalDetails', { oasAge, oas })
      );

      this.setState({
        ableToGoNext: true,
        errors: { ...errors, oasAge: null, oas: null },
      });
    }
  };

  handleAgesChange = (e) => {
    const { dispatch, data, cacheInputChanged } = this.props;
    const { personalDetails } = data || {};
    const { errors } = this.state;
    const { name, value } = e.target;
    const limitRegex = /^[0-9]{1,9}$/;
    if (value !== '' && !limitRegex.test(value)) return;
    const { cpp, oas, cpp2, oas2 } = personalDetails || {};

    if (!cacheInputChanged) {
      dispatch(Storage.setItem('cacheInputChanged', true));
      dispatch(Storage.setItem('cachePDChanged', true));
    }

    dispatch(
      Planning.updateBlueprintData('personalDetails', {
        [name]: Number(value) > 70 ? 70 : Number(value),
        cpp: name === 'cppAge' ? null : cpp,
        oas: name === 'oasAge' ? null : oas,
        cpp2: name === 'cppAge2' ? null : cpp2,
        oas2: name === 'oasAge2' ? null : oas2,
      })
    );

    this.setState({
      ableToGoNext: true,
      errors: { ...errors, [name]: null },
      warning: { [name]: null },
    });
  };

  handleMoneyChange = (e) => {
    const { dispatch, data, oasDataset, cacheInputChanged, cppEstimator } =
      this.props;
    const { personalDetails } = data || {};
    const { isCouple } = this.state;
    const { name } = e.target;
    let { value } = e.target;
    let overMaxPrompt = null;
    let warningUpdate = {};

    if (!cacheInputChanged) {
      dispatch(Storage.setItem('cacheInputChanged', true));
      dispatch(Storage.setItem('cachePDChanged', true));
    }

    if (!value) {
      dispatch(Planning.deleteBlueprintData('personalDetails', name));
    } else {
      value = value.replace(/,/g, ''); // Remove commas
      const limitRegex = /^[0-9]+(\.[0-9]{0,2})?$/;
      if (value !== '' && !limitRegex.test(value)) return;

      value = Number(value);
      let money = null;

      if (name === 'cpp' || name === 'cpp2') {
        const { cppAge, cppAge2 } = personalDetails || {};
        const tempCppAge = isCouple ? Number(cppAge2) : Number(cppAge);
        let max = Number.MIN_SAFE_INTEGER;

        const currentYear = new Date().getFullYear();
        const body = {
          retirement_year: currentYear + tempCppAge - personalDetails.age,
          start_cpp_age: tempCppAge,
          years_contrib: '45',
          primary_caregiver: false,
          years_primary_caregiver: 0,
          is_earning_over_average: true,
          is_earning_14pct_higher: true,
        };
        if (tempCppAge !== '' && tempCppAge >= 60) {
          dispatch(Planning.calcCpp({ ...body }));
          max = cppEstimator.result;

          if (value > max && max > 100) {
            money = max;
            overMaxPrompt =
              'This value is the maximum annual benefits for your CPP/QPP start age and year.';
          } else {
            money = value;
          }

          warningUpdate = isCouple
            ? { cpp2: overMaxPrompt }
            : { cpp: overMaxPrompt };
        }
      }

      if (name === 'oas' || name === 'oas2') {
        const { oasAge, oasAge2 } = personalDetails || {};
        const tempOasAge = isCouple ? Number(oasAge2) : Number(oasAge);
        let max = Number.MIN_SAFE_INTEGER;

        if (!tempOasAge || tempOasAge > 70) {
          max = oasDataset.find(
            (item) =>
              item.amount_type === 'maximum' &&
              item.pension_name === 'OAS' &&
              Number(item.age) === 70
          ).amount;
        } else if (tempOasAge < 65) {
          max = oasDataset.find(
            (item) =>
              item.amount_type === 'maximum' &&
              item.pension_name === 'OAS' &&
              Number(item.age) === 65
          ).amount;
        } else {
          max = oasDataset.find(
            (item) =>
              item.amount_type === 'maximum' &&
              item.pension_name === 'OAS' &&
              Number(item.age) === tempOasAge
          ).amount;
        }

        if (value > max && max > 100) {
          money = max;
          overMaxPrompt =
            'This value is the maximum annual benefits for your OAS start age.';
        } else {
          money = value;
        }

        warningUpdate = isCouple
          ? { oas2: overMaxPrompt }
          : { oas: overMaxPrompt };
      }

      if (limitRegex.test(value)) {
        dispatch(
          Planning.updateBlueprintData('personalDetails', {
            [name]: money || value,
          })
        );
      }

      this.setState((prevState) => ({
        ableToGoNext: true,
        errors: { ...prevState.errors, [name]: null },
        warning: { ...prevState.warning, ...warningUpdate },
      }));
    }
  };

  handleChange = (e, data) => {
    const { dispatch, cacheInputChanged } = this.props;
    const { name, value } = data;
    const { errors } = this.state;

    if (!cacheInputChanged) {
      dispatch(Storage.setItem('cacheInputChanged', true));
      dispatch(Storage.setItem('cachePDChanged', true));
    }

    dispatch(
      Planning.updateBlueprintData('personalDetails', {
        [name]: value,
      })
    );

    this.setState({
      ableToGoNext: true,
      errors: { ...errors, [name]: null },
    });
  };

  handleKeyPress = (e) => e.key === 'Enter' && e.preventDefault();

  toggleSpendingModal = (val) => this.setState({ isSpendingModalOpen: val });

  toggleOasModal = (val) => this.setState({ isOasModalOpen: val });

  toggleCppModal = (val) => this.setState({ isCppModalOpen: val });

  scrollHandler = (to, duration) => {
    if (duration <= 0) return false;
    const el = document.scrollingElement || document.documentElement;
    const difference = to - el.scrollTop;
    const perTick = (difference / duration) * 10;

    return setTimeout(() => {
      el.scrollTop += perTick;
      if (el.scrollTop === to) {
        return false;
      }
      return this.scrollHandler(to, duration - 10);
    }, 10);
  };

  calcShortfall = () => {
    const { data } = this.props;
    const {
      spending_amount,
      cpp,
      cpp2,
      oas,
      oas2,
      pension,
      pension2,
      other,
      other2,
    } = data.personalDetails;

    return (
      spending_amount -
      cpp -
      (cpp2 || 0) -
      oas -
      (oas2 || 0) -
      (pension || 0) -
      (pension2 || 0) -
      (other || 0) -
      (other2 || 0)
    );
  };

  clearError = (key) => {
    const { errors } = this.state;
    if (Object.keys(errors).includes(key))
      this.setState({ errors: { ...errors, [key]: false } });
  };

  validateSection = (section) => {
    const { data } = this.props;
    const { retirementBlueprint, personalDetails } = data || {};
    const { is_couple_plan } = retirementBlueprint || {};
    const { declinePattern } = personalDetails || {};
    const { errors } = this.state;
    const sections = {
      general:
        is_couple_plan === 'true'
          ? [
              'age',
              'retAge',
              'gender',
              'age2',
              'retAge2',
              'gender2',
              'province',
            ]
          : ['age', 'retAge', 'gender', 'province'],
      spending: [
        'spending_amount',
        ...(declinePattern ? ['percent'] : []),
        ...(declinePattern ? ['duration'] : []),
      ],
      retirement:
        is_couple_plan === 'true'
          ? [
              'cppAge',
              'cpp',
              'oasAge',
              'oas',
              'cppAge2',
              'cpp2',
              'oasAge2',
              'oas2',
              'pension',
              'other',
              'pension2',
              'other2',
            ]
          : ['cppAge', 'cpp', 'oasAge', 'oas', 'pension', 'other'],
    };

    return !!Object.keys(errors).find(
      (key) => sections[section].indexOf(key) !== -1 && errors[key]
    );
  };

  submit = () => {
    const { match, dispatch } = this.props;

    const net_spending = this.calcShortfall();
    if (net_spending < 1) {
      return this.setState({ ableToGoNext: false });
    }
    this.setState({ ableToGoNext: true });
    dispatch(Planning.nextSubPage('personalDetails'));
    dispatch(Planning.updateBlueprintData('personalDetails', { net_spending }));
    return this.setTargetWealth(match.params.id === 'new');
  };

  handleNextPage = () => {
    const { dispatch, data } = this.props;
    const { isCouple } = this.state;
    const { is_couple_plan } = data.retirementBlueprint || {};
    const { personalDetails } = data || {};
    const { selectedSpendingTool, spendingToolData } = personalDetails || {};

    if (data.personalDetails.currentSubPage === 1) {
      const required =
        is_couple_plan === 'true'
          ? [
              'age',
              'retAge',
              'gender',
              'age2',
              'retAge2',
              'gender2',
              'province',
            ]
          : ['age', 'retAge', 'gender', 'province'];

      const errorObject = required.reduce((acc, val) => {
        if (val && !personalDetails[val]) {
          acc[val] = true;
        }

        if (val === 'retAge') {
          if (Number(personalDetails[val]) <= Number(personalDetails.age)) {
            acc[val] = 'Retirement age has to be greater than current age.';
          }
        }

        if (val === 'retAge2') {
          if (Number(personalDetails[val]) <= Number(personalDetails.age2)) {
            acc[val] = 'Retirement age has to be greater than current age.';
          }
        }
        return acc;
      }, {});

      const hasError = Object.values(errorObject).find((val) => val);
      if (hasError) {
        this.setState({
          errors: { ...errorObject },
          isCouple:
            (errorObject.cppAge ||
              errorObject.cpp ||
              errorObject.oasAge ||
              errorObject.oas) &&
            !(
              errorObject.cppAge2 ||
              errorObject.cpp2 ||
              errorObject.oasAge2 ||
              errorObject.oas2
            )
              ? false
              : !(
                  errorObject.cppAge ||
                  errorObject.cpp ||
                  errorObject.oasAge ||
                  errorObject.oas
                ) &&
                (errorObject.cppAge2 ||
                  errorObject.cpp2 ||
                  errorObject.oasAge2 ||
                  errorObject.oas2)
              ? true
              : isCouple,
        });
      } else {
        this.setState({
          isCouple: false, // Reset to false when there are no errors
        });
        return dispatch(Planning.nextSubPage('personalDetails'));
      }
    } else if (data.personalDetails.currentSubPage === 2) {
      if (selectedSpendingTool === 'industryStandard') {
        const required =
          is_couple_plan === 'true'
            ? ['wagePct2', 'wagePct', 'retVal', 'retVal2']
            : ['wagePct', 'retVal'];

        const errorObject = required.reduce((acc, val) => {
          if (val && !spendingToolData[val]) acc[val] = true;

          return acc;
        }, {});
        const hasError = Object.values(errorObject).find((val) => val);

        if (hasError) {
          this.setState({
            errors: { ...errorObject },
          });
        } else {
          this.setState({
            isCouple: false, // Reset to false when there are no errors
          });
          return dispatch(Planning.nextSubPage('personalDetails'));
        }
      } else if (selectedSpendingTool === 'bigPicture') {
        if (!spendingToolData.blunter) {
          this.setState({
            errors: { blunter: true },
          });
        } else return dispatch(Planning.nextSubPage('personalDetails'));
      } else if (selectedSpendingTool === 'manual') {
        if (!spendingToolData.manual) {
          this.setState({
            errors: { manual: true },
          });
        } else return dispatch(Planning.nextSubPage('personalDetails'));
      } else if (selectedSpendingTool === 'personalized')
        return dispatch(Planning.nextSubPage('personalDetails'));
    } else if (data.personalDetails.currentSubPage === 3) {
      if (!personalDetails.declinePattern)
        return dispatch(Planning.nextSubPage('personalDetails'));
      // if either the 'percent' or 'years' input has some data, make the sure the other one does as well
      if (
        (personalDetails.declinePattern.percent === undefined &&
          personalDetails.declinePattern.duration !== undefined) ||
        (personalDetails.declinePattern.duration === undefined &&
          personalDetails.declinePattern.percent !== undefined)
      ) {
        this.setState({
          errors: {
            percent: personalDetails.declinePattern.percent === undefined,
            duration: personalDetails.declinePattern.duration === undefined,
          },
        });
      } else {
        this.setState({
          errors: {
            percent: false,
            duration: false,
          },
        });
        if (
          personalDetails.declinePattern.percent === undefined &&
          personalDetails.declinePattern.duration === undefined
        )
          dispatch(
            Planning.deleteBlueprintData('personalDetails', 'declinePattern')
          );
        this.setState({
          isCouple: false, // Reset to false when there are no errors
        });
        return dispatch(Planning.nextSubPage('personalDetails'));
      }
    } else if (data.personalDetails.currentSubPage === 4) {
      const required =
        is_couple_plan === 'true'
          ? ['cppAge', 'cpp', 'cppAge2', 'cpp2']
          : ['cppAge', 'cpp'];

      const errorObject = required.reduce((acc, val) => {
        if (val && !personalDetails[val]) {
          acc[val] = true;
        }

        if (val === 'cppAge') {
          if (
            Number(personalDetails[val]) > 70 ||
            Number(personalDetails[val]) < 60
          ) {
            acc[val] =
              'You can only start collecting CPP/QPP benefits between age 60 and age 70.';
          } else if (
            Number(personalDetails[val]) < Number(personalDetails.retAge)
          ) {
            acc[val] =
              'Only cases where you start taking CPP at or after retirement are considered.';
          }
        }

        if (val === 'cppAge2') {
          if (
            Number(personalDetails[val]) > 70 ||
            Number(personalDetails[val]) < 60
          ) {
            acc[val] =
              'You can only start collecting CPP/QPP benefits between age 60 and age 70.';
          } else if (
            Number(personalDetails[val]) < Number(personalDetails.retAge2)
          ) {
            acc[val] =
              'Only cases where you start taking CPP at or after retirement are considered.';
          }
        }
        return acc;
      }, {});

      const hasError = Object.values(errorObject).find((val) => val);

      if (hasError) {
        this.setState({
          errors: { ...errorObject },
          isCouple:
            (errorObject.cppAge || errorObject.cpp) &&
            !(errorObject.cppAge2 || errorObject.cpp2)
              ? false
              : !(errorObject.cppAge || errorObject.cpp) &&
                (errorObject.cppAge2 || errorObject.cpp2)
              ? true
              : isCouple,
        });
      } else {
        this.setState({
          isCouple: false, // Reset to false when there are no errors
        });
        return dispatch(Planning.nextSubPage('personalDetails'));
      }
    } else if (data.personalDetails.currentSubPage === 5) {
      const required =
        is_couple_plan === 'true'
          ? ['oasAge', 'oas', 'oasAge2', 'oas2']
          : ['oasAge', 'oas'];

      const errorObject = required.reduce((acc, val) => {
        if (val && !personalDetails[val]) {
          acc[val] = true;
        }

        if (val === 'oasAge') {
          if (
            Number(
              personalDetails[val] > 70 || Number(personalDetails[val]) < 65
            )
          ) {
            acc[val] =
              'You can only start collecting OAS benefits between age 65 and age 70.';
          } else if (
            Number(personalDetails[val]) < Number(personalDetails.retAge)
          ) {
            acc[val] =
              'Only cases where you start taking OAS at or after retirement are considered.';
          }
        }

        if (val === 'oasAge2') {
          if (
            Number(
              personalDetails[val] > 70 || Number(personalDetails[val]) < 65
            )
          ) {
            acc[val] =
              'You can only start collecting OAS benefits between age 65 and age 70.';
          } else if (
            Number(personalDetails[val]) < Number(personalDetails.retAge2)
          ) {
            acc[val] =
              'Only cases where you start taking OAS at or after retirement are considered.';
          }
        }

        return acc;
      }, {});

      const hasError = Object.values(errorObject).find((val) => val);

      if (hasError) {
        this.setState({
          errors: { ...errorObject },
          isCouple:
            (errorObject.oasAge || errorObject.oas) &&
            !(errorObject.oasAge2 || errorObject.oas2)
              ? false
              : !(errorObject.oasAge || errorObject.oas) &&
                (errorObject.oasAge2 || errorObject.oas2)
              ? true
              : isCouple,
        });
      } else {
        this.setState({
          isCouple: false, // Reset to false when there are no errors
        });
        return dispatch(Planning.nextSubPage('personalDetails'));
      }
    } else {
      this.setState({
        isCouple: false, // Reset to false when there are no errors
      });
      return dispatch(Planning.nextSubPage('personalDetails'));
    }
    return null;
  };

  handlePreviousPage = () => {
    const { dispatch, history, data, match } = this.props;
    if (data.personalDetails.currentSubPage === 1)
      return history.push(`/planning/blueprint/${match.params.id}/overview`);

    return dispatch(Planning.previousSubPage('personalDetails'));
  };

  renderCoupleSwitcher = () => {
    const { dispatch, data } = this.props;
    const { is_couple_plan } = data.retirementBlueprint || {};
    const { isCouple } = this.state;
    return (
      is_couple_plan === 'true' && (
        <OptionsWrapper>
          <div
            className={cn('select', { active: !isCouple })}
            onClick={() => {
              this.setState({ isCouple: false });
              dispatch(Planning.cppClearInputs());
              dispatch(Planning.oasClearInputs());
            }}
            style={{
              fontSize: '1.2rem',
              padding: '6px',
              borderTopLeftRadius: 5,
              borderBottomLeftRadius: 5,
            }}
          >
            You
          </div>
          <div
            className={cn('select', { active: isCouple })}
            onClick={() => {
              this.setState({ isCouple: true });
              dispatch(Planning.cppClearInputs());
              dispatch(Planning.oasClearInputs());
            }}
            style={{
              fontSize: '1.2rem',
              padding: '6px',
              borderTopRightRadius: 5,
              borderBottomRightRadius: 5,
            }}
          >
            Spouse
          </div>
        </OptionsWrapper>
      )
    );
  };

  // renders the "General Information" subsection
  renderGISubpages = (
    currentPageInSubSection,
    headerTitle,
    subSectionStyles
  ) => {
    const { data } = this.props;
    const { is_couple_plan } = data.retirementBlueprint || {};
    const { age, retAge, province, gender, age2, retAge2, gender2 } =
      data.personalDetails || {};
    const { errors } = this.state;
    const curAgeInputboxStyle = { width: '200px' };
    const retAgeInputboxStyle = {};
    const sexInputboxStyle = { width: '200px' };

    return (
      <BlueprintContent>
        <Header textAlign="left" size="large" content={headerTitle} />
        <div style={subSectionStyles}>
          <Section className={cn({ invalid: this.validateSection('general') })}>
            {is_couple_plan === 'true' ? (
              //  <div style={{ display: 'flex', flexDirection:  }}>
              <div
                style={{
                  display: 'flex',
                  gap: '8rem',
                }}
              >
                <div>
                  <SpouseGIItem>Current age</SpouseGIItem>
                  <SpouseGIItem>
                    Retirement age
                    <Error
                      style={{
                        position: 'absolute',
                        display: 'block',
                        left: '2rem',
                        top: '200px',
                      }}
                      visible={
                        typeof errors.retAge === 'string' ||
                        typeof errors.retAge2 === 'string'
                      }
                    >
                      {errors.retAge || errors.retAge2}
                    </Error>
                  </SpouseGIItem>
                  <SpouseGIItem>
                    Sex&nbsp;
                    <Popup
                      trigger={
                        <Icon
                          name="question circle outline"
                          style={{
                            fontSize: '15px',
                            verticalAlign: 'initial',
                            color: 'dimgrey',
                          }}
                        />
                      }
                      position="top center"
                      wide
                      content="We are using mortality rates from Statistics Canada in the calculations and the data is available for males and females only."
                    />
                  </SpouseGIItem>
                  <SpouseGIItem>
                    Province of residence
                    <Error
                      style={{ margin: 0, display: 'block' }}
                      visible={typeof errors.province === 'string'}
                    >
                      {errors.province}
                    </Error>
                  </SpouseGIItem>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {/* The row containing the 'You' and 'Spouse' input columns */}
                  <div
                    style={{
                      display: 'flex',
                      marginBottom: '1rem',
                      marginTop: '-24px',
                    }}
                  >
                    {/* 'You' input column */}
                    <div
                      style={{
                        display: 'flex',
                        'flex-direction': 'column',
                        gap: '1.14rem',
                      }}
                    >
                      <InputboxTitle>You</InputboxTitle>
                      <Input
                        className={cn('large', { invalid: errors.age })}
                        name="age"
                        placeholder={age ? '' : 'years'}
                        value={age || ''}
                        onChange={(e) => this.handleAgesChange(e)}
                        style={curAgeInputboxStyle}
                      />
                      <Input
                        className={cn('large', { invalid: errors.retAge })}
                        name="retAge"
                        placeholder={retAge ? '' : 'years'}
                        value={retAge || ''}
                        onChange={(e) => this.handleAgesChange(e)}
                        onKeyPress={this.handleKeyPress}
                        style={retAgeInputboxStyle}
                      />
                      <StyledDropdown
                        className={cn('large', { invalid: errors.gender })}
                        placeholder="sex"
                        name="gender"
                        selection
                        options={[
                          { key: 'm', text: 'Male', value: 'M' },
                          { key: 'f', text: 'Female', value: 'F' },
                        ]}
                        value={gender}
                        onChange={this.handleChange}
                        style={sexInputboxStyle}
                      />
                    </div>
                    {/* 'Spouse' input column */}
                    <div
                      style={{
                        display: 'flex',
                        'flex-direction': 'column',
                        gap: '1.14rem',
                        borderLeft: `2px solid ${colors.grey}`,
                        paddingLeft: '2rem',
                        marginLeft: '2rem',
                      }}
                    >
                      <InputboxTitle>Spouse</InputboxTitle>
                      <Input
                        className={cn('large', { invalid: errors.age2 })}
                        name="age2"
                        placeholder={age2 ? '' : 'years'}
                        value={age2 || ''}
                        onChange={(e) => this.handleAgesChange(e)}
                        style={curAgeInputboxStyle}
                      />
                      <Input
                        className={cn('large', { invalid: errors.retAge2 })}
                        name="retAge2"
                        placeholder={retAge2 ? '' : 'years'}
                        value={retAge2 || ''}
                        onChange={(e) => this.handleAgesChange(e)}
                        onKeyPress={this.handleKeyPress}
                        style={retAgeInputboxStyle}
                      />
                      <StyledDropdown
                        className={cn('large', { invalid: errors.gender2 })}
                        placeholder="sex"
                        name="gender2"
                        selection
                        options={[
                          { key: 'm', text: 'Male', value: 'M' },
                          { key: 'f', text: 'Female', value: 'F' },
                        ]}
                        value={gender2}
                        onChange={this.handleChange}
                        style={sexInputboxStyle}
                      />
                    </div>
                  </div>
                  <StyledDropdown
                    className={cn('large', { invalid: errors.province })}
                    style={{ width: '100%' }}
                    name="province"
                    placeholder={province ? '' : 'province'}
                    selection
                    options={this.getProvinces()}
                    value={province}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            ) : (
              <div className="general-info-questions">
                <div>
                  <div className="title">Current age</div>
                  <Input
                    className={cn('large', { invalid: errors.age })}
                    name="age"
                    placeholder={age ? '' : 'years'}
                    value={age || ''}
                    onChange={(e) => this.handleAgesChange(e)}
                  />
                </div>
                <div>
                  <div className="title">
                    Retirement age
                    <Error
                      style={{ margin: 0, display: 'block' }}
                      visible={typeof errors.retAge === 'string'}
                    >
                      {errors.retAge}
                    </Error>
                  </div>
                  <Input
                    className={cn('large', { invalid: errors.retAge })}
                    name="retAge"
                    placeholder={retAge ? '' : 'years'}
                    value={retAge || ''}
                    onChange={(e) => this.handleAgesChange(e)}
                    onKeyPress={this.handleKeyPress}
                  />
                </div>
                <div>
                  <div className="title">
                    Sex&nbsp;
                    <Popup
                      trigger={
                        <Icon
                          name="question circle outline"
                          style={{
                            fontSize: '15px',
                            verticalAlign: 'initial',
                            color: 'dimgrey',
                          }}
                        />
                      }
                      position="top center"
                      wide
                      content="We are using mortality rates from Statistics Canada in the calculations and the data is available for males and females only."
                    />
                  </div>
                  <StyledDropdown
                    className={cn('large', { invalid: errors.gender })}
                    placeholder="sex"
                    name="gender"
                    selection
                    style={{ width: '200px' }}
                    options={[
                      { key: 'm', text: 'Male', value: 'M' },
                      { key: 'f', text: 'Female', value: 'F' },
                    ]}
                    value={gender}
                    onChange={this.handleChange}
                  />
                </div>
                <div>
                  <div className="title">
                    Province of residence
                    <Error
                      style={{ margin: 0, display: 'block' }}
                      visible={typeof errors.province === 'string'}
                    >
                      {errors.province}
                    </Error>
                  </div>
                  <StyledDropdown
                    className={cn('large', { invalid: errors.province })}
                    name="province"
                    placeholder={province ? '' : 'province'}
                    selection
                    style={{ width: '200px', height: '25px' }}
                    options={this.getProvinces()}
                    value={province}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            )}
          </Section>
        </div>
        <NavigationButtons
          handlePreviousPage={this.handlePreviousPage}
          handleNextPage={this.handleNextPage}
          handleSubmit={this.submit}
        />
      </BlueprintContent>
    );
  };

  // renders the "Spending Needs" subsection
  renderSpendingNeedsSubpages = (
    currentPageInSubSection,
    headerTitle,
    subSectionStyles
  ) => {
    const { data, scrollTo } = this.props;
    const { isCouplePlan } = data.retirementBlueprint || {};
    const { is_couple_plan } = data.retirementBlueprint || {};

    const { province, declinePattern, selectedSpendingTool, spendingToolData } =
      data.personalDetails || {};

    const { errors, isSpendingModalOpen } = this.state;

    if (
      currentPageInSubSection === 2 &&
      selectedSpendingTool !== 'manual' &&
      selectedSpendingTool !== 'bigPicture'
    ) {
      if (scrollTo) {
        scrollTo.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }

    return (
      <BlueprintContent>
        <Header textAlign="left" size="large" content={headerTitle} />
        <div style={subSectionStyles}>
          <Section
            className={cn({ invalid: this.validateSection('spending') })}
          >
            {(() => {
              switch (currentPageInSubSection) {
                case 1:
                  return (
                    <ul>
                      <li className="visible">
                        {isCouplePlan ? (
                          <label>
                            Estimate the amount (in today’s dollars) you and
                            your spouse expect to spend per year in retirement.
                          </label>
                        ) : (
                          <label>
                            Estimate the amount (in today’s dollars) you expect
                            to spend per year in retirement.
                          </label>
                        )}
                      </li>
                      <li className="visible">
                        <label>
                          Use one of the following approaches to help gauge your
                          retirement spending needs:
                        </label>
                      </li>
                      {province ? (
                        <SpendingTools
                          isOpen={isSpendingModalOpen}
                          toggle={this.toggleSpendingModal}
                          province={province}
                          spendingTool={selectedSpendingTool || 'manual'} // default tool is 'manual'
                          spendingToolData={spendingToolData}
                          isCouplePlan={is_couple_plan === 'true'}
                          data={data}
                          errors={errors}
                          clearError={this.clearError}
                        />
                      ) : (
                        'province is null'
                      )}
                    </ul>
                  );

                case 2:
                  return (
                    <ul className="spending-needs-questions-drop-by">
                      <li
                        className="visible"
                        style={{ height: 'auto', marginBottom: '1rem' }}
                      >
                        <label
                          style={{
                            'margin-bottom': '10px',
                            width: '600px',
                          }}
                        >
                          Your yearly retirement spending amount that you just
                          entered will stay constant in real terms. However, you
                          may have heard of references to the "go-go",
                          "slow-go", and "no-go" phases of retirement, i.e., the
                          idea that your spending needs may drop as you age,
                          when activities are gradually reduced.
                        </label>
                      </li>
                      <li
                        className="visible spending-needs-drop-by-li"
                        style={{ height: 'auto', marginTop: '2rem' }}
                      >
                        <label>
                          Assume that{' '}
                          {isCouplePlan ? "you and your spouse's" : 'your'}{' '}
                          spending needs will drop by
                        </label>
                        <div id="drop-by-inputs-container">
                          <DeclinePatternInput
                            name="percent"
                            label={{ basic: true, content: '%' }}
                            labelPosition="right"
                            value={
                              (declinePattern && declinePattern.percent) || ''
                            }
                            onChange={(e) =>
                              this.handleDeclinePatternChange(e, declinePattern)
                            }
                            wideLabel={false}
                            invalid={!!errors.percent}
                          />
                          <div
                            style={{
                              display: 'inline-block',
                              margin: '2px 5px 5px 35px',
                            }}
                          >
                            every
                          </div>
                          <DeclinePatternInput
                            name="duration"
                            label={{ basic: true, content: 'years' }}
                            labelPosition="right"
                            value={
                              (declinePattern && declinePattern.duration) || ''
                            }
                            onChange={(e) =>
                              this.handleDeclinePatternChange(e, declinePattern)
                            }
                            wideLabel="true"
                            invalid={!!errors.duration}
                          />
                          <Popup
                            trigger={
                              <Icon
                                name="question circle outline"
                                style={{
                                  fontSize: '15px',
                                  verticalAlign: 'initial',
                                  color: 'dimgrey',
                                  transform: 'translateX(50px) translateY(4px)',
                                }}
                              />
                            }
                            position="top center"
                            wide
                            content={
                              isCouplePlan
                                ? 'For example, drop by 10% every 10 years. This rule will be applied to each spouse according to their retirement age.'
                                : 'For example, drop by 10% every 10 years.'
                            }
                          />
                        </div>
                      </li>
                    </ul>
                  );

                default:
                  return <Loading active />;
              }
            })()}
          </Section>
        </div>
        <NavigationButtons
          handlePreviousPage={this.handlePreviousPage}
          handleNextPage={this.handleNextPage}
          handleSubmit={this.submit}
        />
      </BlueprintContent>
    );
  };

  // render the "Retirement Income" subsection
  renderRISubpages = (
    currentPageInSubSection,
    headerTitle,
    subSectionStyles,
    nextButtonSubmits = false
  ) => {
    const { data, partnerName } = this.props;
    const {
      cppAge,
      oasAge,
      cpp,
      oas,
      pension,
      other,
      cppAge2,
      oasAge2,
      cpp2,
      oas2,
      pension2,
      other2,
    } = data.personalDetails || {};
    const { isCouple, warning, errors, ableToGoNext } = this.state;
    return (
      <BlueprintContent>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            maxWidth: '58.5rem',
          }}
        >
          <Header textAlign="left" size="large" content={headerTitle} />
          {this.renderCoupleSwitcher()}
        </div>
        <div style={subSectionStyles}>
          <Section>
            {(() => {
              switch (currentPageInSubSection) {
                case 1: // CANADIAN PENSION PLAN SUBPAGE
                  return isCouple ? (
                    <div>
                      <div className="ri-questions">
                        <div>
                          <div className="title">
                            At what age would you like to start collecting CPP?
                            <Popup
                              trigger={
                                <Icon
                                  name="question circle outline"
                                  style={{
                                    fontSize: '15px',
                                    verticalAlign: 'initial',
                                    color: 'dimgrey',
                                    marginLeft: '3px',
                                  }}
                                />
                              }
                              position="top center"
                              wide
                              content="You can only start collecting CPP/QPP benefits between age 60 and age 70."
                            />
                            <Error
                              style={{ margin: 0, display: 'block' }}
                              visible={typeof errors.cppAge2 === 'string'}
                            >
                              {errors.cppAge2}
                            </Error>
                          </div>
                          <Input
                            className={cn('large', { invalid: errors.cppAge2 })}
                            name="cppAge2"
                            placeholder="years"
                            value={cppAge2 || ''}
                            onChange={(e) => this.handleAgesChange(e)}
                          />
                        </div>
                        <div>
                          <div className="title">
                            Expected CPP/QPP annual benefits in today's dollars
                            <Error
                              style={{ margin: 0, display: 'block' }}
                              visible={typeof warning.cpp2 === 'string'}
                            >
                              {warning.cpp2}
                            </Error>
                          </div>
                          {!cppAge2 ? (
                            <Popup
                              trigger={
                                <StyledDollarSemanticInput
                                  className={cn('large', 'disabled', {
                                    invalid: errors.cpp2,
                                  })}
                                  name="cpp2"
                                  value={cpp2 === 0 ? '0' : cpp2 || ''}
                                  onChange={(e) => this.handleMoneyChange(e)}
                                  disabled
                                />
                              }
                              position="top center"
                              content="Please enter CPP/QPP start age."
                            />
                          ) : (
                            <StyledDollarSemanticInput
                              className={cn('large', { invalid: errors.cpp2 })}
                              name="cpp2"
                              value={cpp2 === 0 ? '0' : cpp2 || ''}
                              onChange={(e) => this.handleMoneyChange(e)}
                              onFocus={() =>
                                this.setState({
                                  warning: {
                                    ...warning,
                                    cpp2: null,
                                  },
                                })
                              }
                              onBlur={() =>
                                this.setState({
                                  warning: {
                                    ...warning,
                                    cpp2: null,
                                  },
                                })
                              }
                              onClick={() =>
                                this.setState({
                                  warning: {
                                    ...warning,
                                    cpp2: null,
                                  },
                                })
                              }
                            />
                          )}
                        </div>
                        <Error
                          style={{
                            marginTop: `${
                              typeof warning.cpp === 'string' ? '-15px' : 0
                            }`,
                            display: 'block',
                            marginBottom: '3px',
                          }}
                          visible={typeof warning.cpp === 'string'}
                        >
                          {warning.cpp}
                        </Error>
                        <div className="cpp-open-button-container">
                          {isGlobeInvestor(partnerName) ? (
                            <a
                              href="https://www.theglobeandmail.com/investing/personal-finance/tools/cpp-estimator/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Button
                                style={{
                                  backgroundColor: colors.blue,
                                  color: colors.white,
                                }}
                              >
                                CPP/QPP Estimator
                              </Button>
                            </a>
                          ) : (
                            <Button
                              style={{
                                backgroundColor: colors.blue,
                                color: colors.white,
                              }}
                              onClick={() => this.toggleCppModal(true)}
                            >
                              CPP/QPP Estimator
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="ri-questions">
                      <div>
                        <div className="title">
                          At what age would you like to start collecting CPP?
                          <Popup
                            trigger={
                              <Icon
                                name="question circle outline"
                                style={{
                                  fontSize: '15px',
                                  verticalAlign: 'initial',
                                  color: 'dimgrey',
                                  marginLeft: '3px',
                                }}
                              />
                            }
                            position="top center"
                            wide
                            content="You can only start collecting CPP/QPP benefits between age 60 and age 70."
                          />
                          <Error
                            style={{ margin: 0, display: 'block' }}
                            visible={typeof errors.cppAge === 'string'}
                          >
                            {errors.cppAge}
                          </Error>
                        </div>
                        <Input
                          className={cn('large', { invalid: errors.cppAge })}
                          name="cppAge"
                          placeholder="years"
                          value={cppAge || ''}
                          onChange={(e) => this.handleAgesChange(e)}
                        />
                      </div>
                      <div>
                        <div className="title">
                          Expected CPP/QPP annual benefits in today's dollars
                        </div>
                        {!cppAge ? (
                          <Popup
                            trigger={
                              <StyledDollarSemanticInput
                                className={cn('large', { invalid: errors.cpp })}
                                name="cpp"
                                value={cpp === 0 ? '0' : cpp || ''}
                                onChange={(e) => this.handleMoneyChange(e)}
                                disabled
                              />
                            }
                            position="top center"
                            content="Please enter CPP/QPP start age."
                          />
                        ) : (
                          <StyledDollarSemanticInput
                            className={cn('large', { invalid: errors.cpp })}
                            name="cpp"
                            value={cpp === 0 ? '0' : cpp || ''}
                            onChange={(e) => this.handleMoneyChange(e)}
                            onFocus={() =>
                              this.setState({
                                warning: { ...warning, cpp: null },
                              })
                            }
                            onBlur={() =>
                              this.setState({
                                warning: { ...warning, cpp: null },
                              })
                            }
                            onClick={() =>
                              this.setState({
                                warning: { ...warning, cpp: null },
                              })
                            }
                          />
                        )}
                      </div>
                      <Error
                        className="ccp-error"
                        style={{
                          marginTop: `${
                            typeof warning.cpp === 'string' ? '-15px' : 0
                          }`,
                          display: 'block',
                          marginBottom: '3px',
                        }}
                        visible={typeof warning.cpp === 'string'}
                      >
                        {warning.cpp}
                      </Error>
                      <div className="cpp-open-button-container">
                        {isGlobeInvestor(partnerName) ? (
                          <a
                            href="https://www.theglobeandmail.com/investing/personal-finance/tools/cpp-estimator/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Button
                              style={{
                                backgroundColor: colors.blue,
                                color: colors.white,
                              }}
                            >
                              CPP/QPP Estimator
                            </Button>
                          </a>
                        ) : (
                          <Button
                            style={{
                              backgroundColor: colors.blue,
                              color: colors.white,
                            }}
                            onClick={() => this.toggleCppModal(true)}
                          >
                            CPP/QPP Estimator
                          </Button>
                        )}
                      </div>
                    </div>
                  );

                case 2: // OLD AGE SECURITY SUBPAGE
                  return isCouple ? (
                    <div>
                      <div className="ri-questions">
                        <div>
                          <div className="title">
                            At what age would you like to start collecting OAS?
                            <Popup
                              trigger={
                                <Icon
                                  name="question circle outline"
                                  style={{
                                    fontSize: '15px',
                                    verticalAlign: 'initial',
                                    color: 'dimgrey',
                                    marginLeft: '3px',
                                  }}
                                />
                              }
                              position="top center"
                              wide
                              content="You can only start collecting OAS benefits between age 65 and age 70."
                            />
                            <Error
                              style={{ margin: 0, display: 'block' }}
                              visible={typeof errors.oasAge2 === 'string'}
                            >
                              {errors.oasAge2}
                            </Error>
                          </div>
                          <Input
                            className={cn('large', { invalid: errors.oasAge2 })}
                            name="oasAge2"
                            placeholder="years"
                            value={oasAge2 || ''}
                            onChange={(e) => this.handleAgesChange(e)}
                          />
                        </div>
                        <div>
                          <div className="title">
                            Expected OAS annual benefits in today's dollars
                          </div>
                          {!oasAge2 ? (
                            <Popup
                              trigger={
                                <StyledDollarSemanticInput
                                  className={cn('large', 'no-age', {
                                    invalid: errors.oas2,
                                  })}
                                  name="oas2"
                                  value={oas2 === 0 ? '0' : oas2 || ''}
                                  onChange={(e) => this.handleMoneyChange(e)}
                                  disabled
                                />
                              }
                              position="top center"
                              content="Please enter OAS start age."
                            />
                          ) : (
                            <StyledDollarSemanticInput
                              className={cn('large', { invalid: errors.oas2 })}
                              name="oas2"
                              value={oas2 === 0 ? '0' : oas2 || ''}
                              onChange={(e) => this.handleMoneyChange(e)}
                              onFocus={() =>
                                this.setState({
                                  warning: {
                                    ...warning,
                                    oas2: null,
                                  },
                                })
                              }
                              onBlur={() =>
                                this.setState({
                                  warning: {
                                    ...warning,
                                    oas2: null,
                                  },
                                })
                              }
                              onClick={() =>
                                this.setState({
                                  warning: {
                                    ...warning,
                                    oas2: null,
                                  },
                                })
                              }
                            />
                          )}
                        </div>
                        <Error
                          style={{
                            marginTop: `${
                              typeof warning.oas2 === 'string' ? '-15px' : 0
                            }`,
                            display: 'block',
                            marginBottom: '3px',
                          }}
                          visible={typeof warning.oas2 === 'string'}
                        >
                          {warning.oas2}
                        </Error>
                        <div className="oas-open-button-container">
                          {isGlobeInvestor(partnerName) ? (
                            <a
                              href="https://www.theglobeandmail.com/investing/personal-finance/tools/oas-estimator/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Button
                                style={{
                                  backgroundColor: colors.blue,
                                  color: colors.white,
                                }}
                              >
                                OAS Estimator
                              </Button>
                            </a>
                          ) : (
                            <Button
                              style={{
                                backgroundColor: colors.blue,
                                color: colors.white,
                              }}
                              onClick={() => this.toggleOasModal(true)}
                            >
                              OAS Estimator
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="ri-questions">
                      <div>
                        <div className="title">
                          At what age would you like to start collecting OAS?
                          <Popup
                            trigger={
                              <Icon
                                name="question circle outline"
                                style={{
                                  fontSize: '15px',
                                  verticalAlign: 'initial',
                                  color: 'dimgrey',
                                  marginLeft: '3px',
                                }}
                              />
                            }
                            position="top center"
                            wide
                            content="You can only start collecting OAS benefits between age 65 and age 70."
                          />
                          <Error
                            style={{ margin: 0, display: 'block' }}
                            visible={typeof errors.oasAge === 'string'}
                          >
                            {errors.oasAge}
                          </Error>
                        </div>
                        <Input
                          className={cn('large', { invalid: errors.oasAge })}
                          name="oasAge"
                          placeholder="years"
                          value={oasAge || ''}
                          onChange={(e) => this.handleAgesChange(e)}
                        />
                      </div>
                      <div>
                        <div className="title">
                          <label>
                            Expected OAS annual benefits in today's dollars
                          </label>
                        </div>
                        {!oasAge ? (
                          <Popup
                            trigger={
                              <StyledDollarSemanticInput
                                className={cn('large', 'no-age', {
                                  invalid: errors.oas,
                                })}
                                name="oas"
                                value={oas === 0 ? '0' : oas || ''}
                                onChange={(e) => this.handleMoneyChange(e)}
                                disabled
                              />
                            }
                            position="top center"
                            content="Please enter OAS start age."
                          />
                        ) : (
                          <StyledDollarSemanticInput
                            className={cn('large', { invalid: errors.oas })}
                            name="oas"
                            value={oas === 0 ? '0' : oas || ''}
                            onChange={(e) => this.handleMoneyChange(e)}
                            onFocus={() =>
                              this.setState({
                                warning: { ...warning, oas: null },
                              })
                            }
                            onBlur={() =>
                              this.setState({
                                warning: { ...warning, oas: null },
                              })
                            }
                            onClick={() =>
                              this.setState({
                                warning: { ...warning, oas: null },
                              })
                            }
                          />
                        )}
                      </div>
                      <Error
                        style={{
                          marginTop: `${
                            typeof warning.oas === 'string' ? '-15px' : 0
                          }`,
                          display: 'block',
                          marginBottom: '3px',
                        }}
                        visible={typeof warning.oas === 'string'}
                      >
                        {warning.oas}
                      </Error>
                      <div className="oas-open-button-container">
                        {isGlobeInvestor(partnerName) ? (
                          <a
                            href="https://www.theglobeandmail.com/investing/personal-finance/tools/oas-estimator/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Button
                              style={{
                                backgroundColor: colors.blue,
                                color: colors.white,
                              }}
                            >
                              OAS Estimator
                            </Button>
                          </a>
                        ) : (
                          <Button
                            style={{
                              backgroundColor: colors.blue,
                              color: colors.white,
                            }}
                            onClick={() => this.toggleOasModal(true)}
                          >
                            OAS Estimator
                          </Button>
                        )}
                      </div>
                    </div>
                  );

                case 3: // EMPLOYER PENSION PLAN SUBPAGE
                  return isCouple ? (
                    <div className="ri-questions epp">
                      <div>
                        <div className="title" style={{ maxWidth: '47rem' }}>
                          This amount is the expected annual pension from your
                          work, if your employer offers a Defined Benefit (DB)
                          Plan. You can look up the amount from your Pension and
                          Benefits Office at work.
                        </div>

                        <StyledDollarSemanticInput
                          className={cn('large', { invalid: false })}
                          style={{ width: '190px' }}
                          name="pension2"
                          placeholder="0"
                          value={pension2 === 0 ? null : pension2 || ''}
                          onChange={(e) => this.handleMoneyChange(e)}
                        />
                      </div>
                      <div
                        className="title"
                        style={{ width: '47rem', fontStyle: 'italic' }}
                      >
                        Note: Savings that you may have in a Defined
                        Contribution (DC) Plan will be entered in a later step
                        under "Target Saving".
                      </div>
                    </div>
                  ) : (
                    <div className="ri-questions epp">
                      <div>
                        <div className="title" style={{ maxWidth: '47rem' }}>
                          This amount is the expected annual pension from your
                          work, if your employer offers a Defined Benefit (DB)
                          Plan. You can look up the amount from your Pension and
                          Benefits Office at work.
                        </div>

                        <StyledDollarSemanticInput
                          className={cn('large', { invalid: false })}
                          style={{ width: '190px' }}
                          name="pension"
                          placeholder="0"
                          value={pension === 0 ? null : pension || ''}
                          onChange={(e) => this.handleMoneyChange(e)}
                        />
                      </div>
                      <div
                        className="title"
                        style={{
                          width: '47rem',
                          fontStyle: 'italic',
                        }}
                      >
                        Note: Savings that you may have in a Defined
                        Contribution (DC) Plan will be entered in a later step
                        under "Target Saving".
                      </div>
                    </div>
                  );
                case 4: // 'OTHER INCOME' SUBPAGE
                  return isCouple ? (
                    <div className="ri-questions">
                      <div>
                        <div className="title" style={{ width: '507px' }}>
                          Expected annual income from other sources, such as an
                          annuity.
                        </div>

                        <StyledDollarSemanticInput
                          className={cn('large', { invalid: false })}
                          name="other2"
                          placeholder="0"
                          value={other2 === 0 ? '0' : other2 || ''}
                          onChange={(e) => this.handleMoneyChange(e)}
                        />
                      </div>
                      {!ableToGoNext ? (
                        <p
                          style={{
                            color: colors.blue,
                            margin: '4rem auto',
                            textAlign: 'center',
                            fontSize: '1.2rem',
                            maxWidth: '50rem',
                          }}
                        >
                          Since you are expecting to receive more income in
                          retirement than what you need to spend, you can either
                          revisit your previous entries or you are good to go
                          for your retirement!
                        </p>
                      ) : null}
                    </div>
                  ) : (
                    <div className="ri-questions">
                      <div>
                        <div className="title" style={{ width: '507px' }}>
                          Expected annual income from other sources, such as an
                          annuity.
                        </div>
                        <StyledDollarSemanticInput
                          className={cn('large', { invalid: false })}
                          name="other"
                          placeholder="0"
                          value={other === 0 ? '0' : other || ''}
                          onChange={(e) => this.handleMoneyChange(e)}
                        />
                      </div>
                      {!ableToGoNext ? (
                        <p
                          style={{
                            color: colors.blue,
                            margin: '4rem auto',
                            textAlign: 'center',
                            fontSize: '1.2rem',
                            maxWidth: '50rem',
                          }}
                        >
                          Since you are expecting to receive more income in
                          retirement than what you need to spend, you can either
                          revisit your previous entries or you are good to go
                          for your retirement!
                        </p>
                      ) : null}
                    </div>
                  );

                default:
                  return <Loading active />;
              }
            })()}
          </Section>
        </div>
        <NavigationButtons
          nextButtonSubmits={nextButtonSubmits}
          handlePreviousPage={this.handlePreviousPage}
          handleNextPage={this.handleNextPage}
          handleSubmit={this.submit}
          showNextButton={!(currentPageInSubSection >= 4 && !ableToGoNext)}
        />
      </BlueprintContent>
    );
  };

  // render out the entire personal details section
  render() {
    const { data, cppDataset, match } = this.props;
    const { isCppModalOpen, isOasModalOpen, isCouple, deleteWarningModalOpen } =
      this.state;
    const subSectionStyles = {
      minHeight: '270px',
      margin: '0rem 1rem 0rem 1rem',
    };

    return (
      <div>
        {(() => {
          if (!data.personalDetails) return null;
          switch (data.personalDetails.currentSubPage) {
            case 1: // render the "General Information" section
              return this.renderGISubpages(
                1,
                'General Information',
                subSectionStyles
              );
            case 2: // render the "Spending needs" subsection
              return this.renderSpendingNeedsSubpages(
                1,
                'Spending Needs',
                subSectionStyles
              );
            case 3: // render the "Spending needs" subsection
              return this.renderSpendingNeedsSubpages(
                2,
                'Spending Needs Adjustment (Optional)',
                subSectionStyles
              );
            case 4: // render the CCP subsection
              return this.renderRISubpages(
                1,
                'Canadian Pension Plan (CPP/QPP)',
                subSectionStyles
              );
            case 5: // render the OAS subsection
              return this.renderRISubpages(
                2,
                'Old Age Security (OAS)',
                subSectionStyles
              );
            case 6: // render the EPP subsection
              return this.renderRISubpages(
                3,
                'Employer Pension Plan (optional)',
                subSectionStyles
              );
            case 7: // render the Other income subsection
              return this.renderRISubpages(
                4,
                'Other Income (optional)',
                subSectionStyles,
                true
              );
            default:
              return (
                <BlueprintContent>
                  <Loading active />
                </BlueprintContent>
              );
          }
        })()}
        <CppModal
          isOpen={isCppModalOpen}
          toggle={this.toggleCppModal}
          updateCppInfo={this.updateCppInfo}
          cppDataset={cppDataset}
          isCouple={isCouple}
        />
        <OasModal
          isOpen={isOasModalOpen}
          toggle={this.toggleOasModal}
          updateOasInfo={this.updateOasInfo}
          isCouple={isCouple}
        />
        <DeleteWarningModal
          isOpen={deleteWarningModalOpen}
          changeModalVisibility={this.setDeleteWarningModalVisibility}
          isSavedBlueprint={match.params.id !== 'new'}
        />
      </div>
    );
  }
}

PersonalDetails.propTypes = {
  modelPortfolios: PropTypes.array,
  match: PropTypes.object,
  history: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  cppDataset: PropTypes.array,
  oasDataset: PropTypes.array,
  data: PropTypes.object,
  cachePB: PropTypes.object,
  cacheTW: PropTypes.object,
  cacheWR: PropTypes.object,
  user_region: PropTypes.string,
  cacheInputChanged: PropTypes.bool,
  cppEstimator: PropTypes.object,
  scrollTo: PropTypes.object,
  partnerName: PropTypes.string,
};

PersonalDetails.defaultProps = {
  modelPortfolios: [],
  data: {},
  match: {},
  history: {},
  cppDataset: [],
  oasDataset: [],
  cachePB: null,
  cacheTW: null,
  cacheWR: null,
  user_region: 'CA',
  cacheInputChanged: false,
  cppEstimator: {},
  scrollTo: {},
  partnerName: '',
};

export default connect((state) => ({
  modelPortfolios: state.Portfolio.model,
  cppDataset: state.Planning.cppDataset,
  oasDataset: state.Planning.oasDataset,
  cachePB: state.Storage.cachePB,
  cacheTW: state.Storage.cacheTW,
  cacheWR: state.Storage.cacheWR,
  cacheTS: state.Storage.cacheTS,
  cacheSR: state.Storage.cacheSR,
  blueprintDeleteWarningSuppress: state.Storage.blueprintDeleteWarningSuppress,
  user_region: state.Storage.user_region || 'CA',
  cacheInputChanged: state.Storage.cacheInputChanged,
  cppEstimator: state.Planning.cppEstimator,
  partnerName: state.Storage['partner-name'],
}))(PersonalDetails);

const BlueprintContent = styled.div`
  width: 100%;
  text-align: left;
  padding: 2.5rem;
  min-height: 350px;
  margin-bottom: 5rem;
`;

const Section = styled.section`
  padding: 1rem;
  padding-top: 0.5rem;
  position: relative;
  text-align: left;
  margin: 0 auto 2rem;
  transition: all 200ms ease;

  .heading {
    position: absolute;
    top: -19px;
    left: 20px;
    padding: 0.5rem;
    background: white;
    font-size: 1.28em;
    font-weight: 700;
  }
  .general-info-questions > div {
    display: flex;
    gap: 8rem;
  }

  .ri-questions > div {
    display: flex;
    gap: 6rem;
    padding-top: calc(0.5rem - 15px);
  }

  .ri-questions > div > .title {
    width: 507px;
  }
  .ri-questions > div > .title {
    width: 507px;
  }
  .ri-questions.epp > div > .title {
    width: 507px;
    margin-top: 1rem;
  }
  .ri-questions.epp > div > .input {
    align-self: start;
    margin-top: 1rem;
  }

  .spending-needs-questions > li {
    display: flex;
    justify-content: flex-start;
    gap: 3rem;
  }

  .spending-needs-questions > li > label {
    width: 600px;
  }

  .spending-needs-questions-drop-by > li {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    width: 47rem;
  }

  .spending-needs-questions-drop-by > li > label {
    width: auto;
  }

  #drop-by-inputs-container {
    display: flex;
    justifycontent: flex-start;
    gap: 0.3rem;
  }

  .title {
    font-size: 1.2rem;
    margin: 1rem 0;
    display: inline-block;
    width: 160px;
  }

  ul {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      position: relative;
      display: flex;
      justify-content: space-between;
      overflow: hidden;
      line-height: normal;
      transition: all 200ms ease;
      margin-top: 1rem;
    }

    label {
      font-size: 1.2rem;
      display: inline-block;
    }
  }

  .description {
    display: inline-block;
    font-size: 1rem;
    padding: 3px 0 0.7rem 1rem;
    line-height: 1rem;
    width: 61%;
    clear: both;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.4;
  }
`;

const Error = styled.div`
  display: inline-block;
  font-size: 1rem;
  font-style: normal;
  color: ${colors.red};

  ${(props) =>
    props.long &&
    css`
      position: absolute;
      bottom: 0;
      left: 4px;
    `}
`;

const StyledDollarSemanticInput = styled(({ children, className, ...rest }) => (
  <SemanticInput
    label={{ basic: true, content: '$' }}
    className={className}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
  >
    {children}
  </SemanticInput>
))`
  width: 200px;
  display: flex;
  align-items: center;
  &.disabled {
    opacity: 0.8;
    input {
      background: ${colors.lightGrey} !important;
    }
    .label {
      background: ${colors.lightGrey} !important;
    }
  }
  &.invalid {
    input {
      border: 1px solid ${colors.red} !important;
      background: rgb(255, 240, 240) !important;
    }
    .label {
      border: 1px solid ${colors.red} !important;
      background: rgb(255, 240, 240) !important;
    }
  }
  .label {
    height: 25px !important;
    display: flex;
    align-items: center;
    font-size: 0.9rem !important;
    padding: 0 0.4rem !important;
    padding-top: 1px !important;
  }
  input {
    padding: 0 !important;
    padding-left: 4px !important;
    height: 25px !important;
    font-size: 0.9rem !important;
  }
`;
const StyledDropdown = styled(({ children, className, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Dropdown className={className} {...rest}>
    {children}
  </Dropdown>
))`
  height: 25px !important;
  min-height: unset !important;
  padding: 0.3rem !important;
  min-width: unset !important;
  float: right;
  align-self: center;
  font-size: 0.9rem !important;
  .text {
    margin-top: 2px !important;
  }
  ${(props) =>
    props.long &&
    css`
      width: 80% !important;
    `}

  &.invalid {
    border: 1px solid ${colors.red} !important;
    background: rgb(255, 240, 240) !important;
  }

  i {
    padding: 0.5rem !important;
  }

  &.large {
    margin: 0;
    clear: both;
  }
`;

const Input = styled.input`
  float: right;
  padding: 0.3rem;
  border-radius: 3px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  transition: all 200ms ease;
  color: rgba(0, 0, 0, 0.8);
  height: 25px !important;
  font-size: 0.9rem;
  text-align: left;
  outline: none;
  width: 200px;
  align-self: center;

  &:focus,
  &:active {
    outline: none;
    border: 1px solid rgb(132, 183, 217);

    &::placeholder {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }

  &.large {
    margin: 0;
    clear: both;
  }

  &.invalid {
    border: 1px solid ${colors.red};
    background: rgb(255, 240, 240);
  }

  &.absolute {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

const OptionsWrapper = styled.div`
  &.disabled {
    opacity: 0.4;
    pointer-events: none;
    cursor: disabled;
  }

  .custom {
    top: 20px;
    right: 10px;
  }

  .select {
    display: inline-block;
    padding: 0.3rem 0.7rem;
    line-height: 15px;
    cursor: pointer;
    font-size: 0.8rem;
    background-color: white;
    color: rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset,
      0 1px 4px 0 rgba(34, 36, 38, 0.1) inset;

    &:hover,
    &:focus {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3) inset,
        0 0 0 0 rgba(0, 0, 0, 0.3) inset;
      color: rgba(0, 0, 0, 0.5);
    }

    &.active {
      box-shadow: none;
      background-color: ${colors.teal};
      color: black;
    }
  }
`;

const SpouseGIItem = styled.div`
  display: block;
  height: 40px;
  padding-top: 15px;
  margin: 0;
  font-size: 1.2rem;
`;

const InputboxTitle = styled.div`
  display: block;
  height: 20px;
  color: ${colors.blue};
  text-align: left;
  font-size: 1.2rem;
`;

const DeclinePatternInput = styled(({ children, className, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SemanticInput className={className} {...rest}>
    {children}
  </SemanticInput>
))`
  height: 25px !important;
  width: 65px !important;

  .label {
    padding: 0 !important;
    text-align: center;
    line-height: 23px;
    font-size: 0.9rem !important;
    width: ${(props) => (props.wideLabel ? '45px' : '30px')};
    border-left: ${(props) =>
      props.invalid ? `1px solid ${colors.red} !important` : 'unset'};
  }

  input {
    border: ${(props) =>
      props.invalid ? `1px solid ${colors.red} !important` : 'unset'};
    background-color: ${(props) =>
      props.invalid ? 'rgb(255,240,240) !important' : 'unset'};
    font-size: 0.9rem !important;
  }
`;
