import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { List, Label, Popup, Icon, Segment } from 'semantic-ui-react';

import { Portfolio, Planning, Storage } from 'actions';
import { colors } from 'utils/colors';
import { formatDollarAmount, isGlobeInvestor } from 'utils/helpers';
import { Table, Message } from 'components';
import SaveModal from './SaveModal';
// correct errors
class Summary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInputWarningOpen: false,
      isTargetWarningOpen: false,
      isSavingWarningOpen: false,
    };
    this.handleToggleClick = this.handleToggleClick.bind(this);
  }

  componentWillMount() {
    const {
      cacheInputChanged,
      cachePDChanged,
      cachePortfolioChanged,
      cacheTargetChanged,
      cacheSavingChanged,
      cacheWealthChanged,
      cacheMSChanged,
      scrollTo,
    } = this.props;
    const { dispatch, portfolio, data } = this.props;

    // Scrolls up to top menu
    if (scrollTo) {
      scrollTo.scrollIntoView({
        behavior: 'smooth',
      });
    }

    // ensure all *completed* sections that the user is not actively clicked on
    // are on page number numSubPages[<section>] + 1 (to display the full red progress bar)
    dispatch(Planning.updateCompletedSectionsPageNumbers('summary', data));

    if (!portfolio.user.length) {
      dispatch(Portfolio.fetchUser());
    }

    if (!portfolio.model.length) {
      dispatch(Portfolio.fetchModel());
    }

    if (cacheInputChanged || cachePDChanged) {
      this.setState({ isInputWarningOpen: true });
    }
    if (cachePortfolioChanged || cacheTargetChanged || cacheMSChanged) {
      this.setState({ isTargetWarningOpen: true });
    }
    if (cacheSavingChanged || cacheWealthChanged) {
      this.setState({ isSavingWarningOpen: true });
    }
    return false;
  }

  componentWillUnmount() {
    const {
      dispatch,
      cacheInputChanged,
      cachePDChanged,
      cachePortfolioChanged,
      cacheTargetChanged,
      cacheSavingChanged,
      cacheMSChanged,
      cacheWealthChanged,
    } = this.props;
    // Remove unsaved data cache
    if (cacheInputChanged) {
      dispatch(Storage.removeItem('cacheInputChanged'));
    }
    if (cachePDChanged) {
      dispatch(Storage.removeItem('cachePDChanged'));
    }
    if (cachePortfolioChanged) {
      dispatch(Storage.removeItem('cachePortfolioChanged'));
    }

    if (cacheTargetChanged) {
      dispatch(Storage.removeItem('cacheTargetChanged'));
    }

    if (cacheSavingChanged) {
      dispatch(Storage.removeItem('cacheSavingChanged'));
    }
    if (cacheMSChanged) {
      dispatch(Storage.removeItem('cacheMSChanged'));
    }
    if (cacheWealthChanged) {
      dispatch(Storage.removeItem('cacheWealthChanged'));
    }
  }

  handleToggleClick() {
    const {
      dispatch,
      cacheInputChanged,
      cachePDChanged,
      cachePortfolioChanged,
      cacheTargetChanged,
      cacheSavingChanged,
    } = this.props;
    if (cacheInputChanged) {
      dispatch(Storage.removeItem('cacheInputChanged'));
    }
    if (cachePDChanged) {
      dispatch(Storage.removeItem('cachePDChanged'));
    }

    if (cachePortfolioChanged) {
      dispatch(Storage.removeItem('cachePortfolioChanged'));
    }

    if (cacheTargetChanged) {
      dispatch(Storage.removeItem('cacheTargetChanged'));
    }

    if (cacheSavingChanged) {
      dispatch(Storage.removeItem('cacheSavingChanged'));
    }
    this.setState({
      isInputWarningOpen: false,
      isTargetWarningOpen: false,
      isSavingWarningOpen: false,
    });
  }

  getPortfolio = (portfolioId, modyType) => {
    const { portfolio, data, partnerName, user_region } = this.props;
    const removeWsForUsVersion = (name) => {
      if (user_region === 'US' || isGlobeInvestor(partnerName)) {
        const removeWs = name.split(' ');
        removeWs.shift();
        return removeWs.join(' ');
      }
      return name;
    };
    const portfolioLabels = {
      'WS All-In': '100% Equities / 0% Bonds',
      'WS Adventurous': '80% Equities / 20% Bonds',
      'WS Classic': '60% Equities / 40% Bonds',
      'WS Cautious': '40% Equities / 60% Bonds',
      'WS Playing-It-Safe': '20% Equities / 80% Bonds',
    };

    if (!portfolio.model) {
      return null;
    }

    if (modyType === 'annuityRate') {
      return (
        <span style={{ fontSize: '1.2rem' }}>
          Annuity: {data.targetWealth.annuityRate}%
        </span>
      );
    }

    let filteredPortfolio = portfolio.model.filter(
      (port) => Number(port.id) === Number(portfolioId)
    );
    if (filteredPortfolio.length) {
      return (
        <span>
          <span style={{ fontSize: '1.2rem' }}>
            {removeWsForUsVersion(filteredPortfolio[0].name)}:{' '}
          </span>
          <span style={{ fontSize: '1rem' }}>
            {portfolioLabels[filteredPortfolio[0].name]}
          </span>
        </span>
      );
    }

    filteredPortfolio = portfolio.user.filter(
      (port) => Number(port.id) === Number(portfolioId)
    );
    if (filteredPortfolio.length) {
      return (
        <span style={{ fontSize: '1.2rem' }}>
          {Object.keys(filteredPortfolio[0]).length &&
            removeWsForUsVersion(filteredPortfolio[0].name)}
        </span>
      );
    }

    return <span style={{ fontSize: '1.2rem', color: 'red' }}>Unmatched</span>;
  };

  getDate = () => {
    const { data } = this.props;
    let year = null;
    let month = null;
    let day = null;

    if (data.updated_on) {
      year = data.updated_on.split('-')[0];
      month = data.updated_on.split('-')[1];
      day = data.updated_on.split('-')[2].split('');

      if (day[0] === '0') {
        day.splice(0, 1);
      }

      day = day.join('');
    } else {
      const d = new Date();
      year = d.getFullYear();
      month =
        d.getMonth() + 1 < 10
          ? `0${(d.getMonth() + 1).toString()}`
          : d.getMonth() + 1;
      day = d.getDate();
    }

    const monthName = {
      '01': 'Jan.',
      '02': 'Feb.',
      '03': 'March',
      '04': 'April',
      '05': 'May',
      '06': 'June',
      '07': 'July',
      '08': 'Aug.',
      '09': 'Sept.',
      10: 'Oct.',
      11: 'Nov.',
      12: 'Dec.',
    };

    return `${monthName[month]} ${day}, ${year}`;
  };

  formatNum = (num) => {
    if (!num) return '$0';
    if (num < 0) {
      return `-$${(Math.round(-1 * num * 100) / 100).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`;
    }
    return `$${(Math.round(num * 100) / 100).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  };

  portfolioIsDeleted = (portfolioId, modelType) => {
    const { portfolio } = this.props;

    return modelType === 'annuityRate'
      ? false
      : !portfolio.model
          .concat(portfolio.user)
          .find((port) => port.id === portfolioId);
  };

  isDisabled = () => {
    const { data, cacheBlueprint } = this.props;
    return JSON.stringify(data) === JSON.stringify(cacheBlueprint);
  };

  renderContent = (name) => (
    <p>
      You need to choose a <strong>{name}</strong> amount in order to save this
      blueprint. <br />
      Please select an amount by clicking the ribbon on the right.
    </p>
  );

  renderSuccess = (mode, target) => {
    const { data } = this.props;
    const targ = +Object.keys(data[`${mode}Result`]).find(
      (key) => data[`${mode}Result`][key] === target
    );

    if (!target) {
      return `${Math.trunc(data[`${mode}Result`].prob_without_savings * 100)}%`;
    }

    return `${targ * 100}%`;
  };

  renderLabel = (section, title, sectionStoreKey) => {
    const { dispatch, history, match } = this.props;
    return (
      <Label
        ribbon="right"
        content={
          <div>
            <span style={{ color: 'black' }}>EDIT</span> {title}
          </div>
        }
        onClick={() => {
          dispatch(
            Planning.updateBlueprintData(sectionStoreKey, {
              currentSubPage: 1,
            })
          );
          history.push(`/planning/blueprint/${match.params.id}/${section}`);
        }}
        style={{ fontSize: '1rem', width: '185px' }}
      />
    );
  };

  renderSingle = () => {
    const { data } = this.props;
    const { personalDetails } = data;
    const {
      age,
      gender,
      province,
      retAge,
      spending_amount,
      cppAge,
      cpp,
      oasAge,
      oas,
      pension,
      other,
      net_spending,
    } = personalDetails || {};

    return (
      <List divided relaxed size="huge">
        <ListItem noBorder>
          <List.Content>
            <List.Header>Sex</List.Header>
            <div className="value">{gender}</div>
          </List.Content>
        </ListItem>
        <ListItem>
          <List.Content>
            <List.Header>Current Age</List.Header>
            <div className="value">{age}</div>
          </List.Content>
        </ListItem>
        <ListItem>
          <List.Content>
            <List.Header>Retirement Age</List.Header>
            <div className="value">{retAge}</div>
          </List.Content>
        </ListItem>
        <ListItem>
          <List.Content>
            <List.Header>Province of residence</List.Header>
            <div className="value">{province}</div>
          </List.Content>
        </ListItem>
        <ListItem>
          <List.Content>
            <List.Header>Annual spending needs</List.Header>
            <div className="value">{formatDollarAmount(spending_amount)}</div>
          </List.Content>
        </ListItem>
        <ListItem style={{ padding: '.5rem 0 0 1.5rem' }}>
          <List.Content>
            <List.Header>Annual retirement income:</List.Header>
          </List.Content>
        </ListItem>
        <ListItem small noBorder style={{ marginLeft: '3rem' }}>
          <List.Content>
            <List.Header className="small">
              {province === 'Quebec' ? 'QPP' : 'CPP'}, starting at age {cppAge}
            </List.Header>
            <div className="value small">{formatDollarAmount(cpp)}</div>
          </List.Content>
        </ListItem>
        <ListItem small style={{ marginLeft: '3rem' }}>
          <List.Content>
            <List.Header className="small">
              OAS, starting at age {oasAge}
            </List.Header>
            <div className="value small">{formatDollarAmount(oas)}</div>
          </List.Content>
        </ListItem>
        <ListItem small style={{ marginLeft: '3rem' }}>
          <List.Content>
            <List.Header className="small">Employment pension</List.Header>
            <div className="value small">{formatDollarAmount(pension)}</div>
          </List.Content>
        </ListItem>
        <ListItem small style={{ marginLeft: '3rem' }}>
          <List.Content>
            <List.Header className="small">Other income</List.Header>
            <div className="value small">{formatDollarAmount(other)}</div>
          </List.Content>
        </ListItem>
        <ListItem>
          <List.Content>
            <List.Header>
              Annual spending needs net of retirement income
            </List.Header>
            <div className="value">{formatDollarAmount(net_spending)}</div>
          </List.Content>
        </ListItem>
        <ListItem>
          <List.Content>
            <List.Header>
              % of spending needs covered by retirement income
            </List.Header>
            <div className="value">{`${(
              ((spending_amount - net_spending) / spending_amount) *
              100
            ).toFixed(1)}%`}</div>
          </List.Content>
        </ListItem>
      </List>
    );
  };

  renderCouple = () => {
    const { data } = this.props;
    const { personalDetails } = data;
    const {
      age,
      age2,
      gender,
      gender2,
      province,
      retAge,
      retAge2,
      spending_amount,
      cpp,
      cpp2,
      oas,
      oas2,
      pension,
      pension2,
      other,
      other2,
      net_spending,
    } = personalDetails || {};

    return (
      <Table basic>
        <Table.Header>
          <Table.Row textAlign="center">
            <Table.HeaderCell width={12} />
            <Table.HeaderCell
              width={2}
              style={{
                paddingLeft: 0,
                color: colors.blue,
                fontSize: '1.2rem',
                fontWeight: '500',
              }}
            >
              You
            </Table.HeaderCell>
            <Table.HeaderCell
              width={2}
              style={{
                paddingLeft: 0,
                color: colors.blue,
                fontSize: '1.2rem',
                fontWeight: '500',
              }}
            >
              Spouse
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row textAlign="center">
            <Cell textAlign="left">Sex</Cell>
            <Cell>{gender}</Cell>
            <Cell>{gender2}</Cell>
          </Table.Row>

          <Table.Row textAlign="center">
            <Cell textAlign="left">Current age</Cell>
            <Cell>{age}</Cell>
            <Cell>{age2}</Cell>
          </Table.Row>

          <Table.Row textAlign="center">
            <Cell textAlign="left">Retirement age</Cell>
            <Cell>{retAge}</Cell>
            <Cell>{retAge2}</Cell>
          </Table.Row>

          <Table.Row textAlign="center">
            <Cell textAlign="left">Province of residence</Cell>
            <Cell colSpan="2">{province}</Cell>
          </Table.Row>

          <Table.Row textAlign="center">
            <Cell textAlign="left">Annual spending needs</Cell>
            <Cell colSpan="2">{formatDollarAmount(spending_amount)}</Cell>
          </Table.Row>

          <Table.Row textAlign="center">
            <Cell textAlign="left">Annual retirement income</Cell>
            <Cell />
            <Cell />
          </Table.Row>

          <Table.Row textAlign="center">
            <Cell textAlign="left" border padded small>
              {`${province === 'Quebec' ? 'Quebec' : 'Canadian'} Pension Plan`}
            </Cell>
            <Cell border small>
              {formatDollarAmount(cpp)}
            </Cell>
            <Cell border small>
              {formatDollarAmount(cpp2)}
            </Cell>
          </Table.Row>

          <Table.Row textAlign="center">
            <Cell textAlign="left" padded small>
              Old Age Security
            </Cell>
            <Cell small>{formatDollarAmount(oas)}</Cell>
            <Cell small>{formatDollarAmount(oas2)}</Cell>
          </Table.Row>

          <Table.Row textAlign="center">
            <Cell textAlign="left" padded small>
              Employment Pension Plan
            </Cell>
            <Cell small>{formatDollarAmount(pension)}</Cell>
            <Cell small>{formatDollarAmount(pension2)}</Cell>
          </Table.Row>

          <Table.Row textAlign="center">
            <Cell textAlign="left" padded small>
              Other income
            </Cell>
            <Cell small>{formatDollarAmount(other)}</Cell>
            <Cell small>{formatDollarAmount(other2)}</Cell>
          </Table.Row>

          <Table.Row textAlign="center">
            <Cell textAlign="left">
              Combined annual spending needs net of retirement income
            </Cell>
            <Cell colSpan="2">{formatDollarAmount(net_spending)}</Cell>
          </Table.Row>
          <Table.Row style={{ fontStyle: 'italic' }}>
            <div style={{ marginBottom: '11px', marginTop: '' }}>
              This is the value right after the first retirement.
              <Popup
                trigger={
                  <Icon
                    name="question circle outline"
                    style={{ color: 'black', fontSize: '15px' }}
                  />
                }
                position="right center"
                content="It will change
            after the second retirement and as additional income kicks in
            (accounted for in our calculations)."
              />
            </div>
          </Table.Row>

          {net_spending > 0 && (
            <Table.Row textAlign="center">
              <Cell textAlign="left">
                % of spending needs covered by retirement income
              </Cell>
              <Cell colSpan="2">
                {(
                  (personalDetails.spending_amount - net_spending) /
                  personalDetails.spending_amount /
                  0.01
                ).toFixed(1)}
                %
              </Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    );
  };

  renderCoupleTax = () => {
    const { data, dispatch, partnerName, history, match } = this.props;
    const { targetWealth, targetSaving, savingResult } = data;
    const { modelType } = targetWealth || {};
    const { monthlySavingChosen, savings_portfolio } = targetSaving || {};
    return (
      <Table basic>
        <Table.Header>
          <Table.Row textAlign="center">
            <Table.HeaderCell width={12} />
            <Table.HeaderCell
              width={2}
              style={{
                paddingLeft: 0,
                color: colors.blue,
                fontSize: '1.2rem',
                fontWeight: '500',
              }}
            />
            <Table.HeaderCell
              width={2}
              style={{
                paddingLeft: 0,
                color: colors.blue,
                fontSize: '1.2rem',
                fontWeight: '500',
              }}
            />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row textAlign="center">
            <Cell textAlign="left">Current savings</Cell>
            <Cell colSpan="2">{formatDollarAmount(targetSaving.init)}</Cell>
          </Table.Row>
          <Table.Row textAlign="center">
            <Cell textAlign="left">Pre-retirement portfolio</Cell>
            <Cell colSpan="2">{this.getPortfolio(savings_portfolio)}</Cell>
          </Table.Row>
          {this.portfolioIsDeleted(savings_portfolio, modelType) &&
            !isGlobeInvestor(partnerName) && (
              <div
                style={{
                  color: 'red',
                  width: '125%',
                  marginBottom: '1rem',
                }}
              >
                Your pre-retirement portfolio has been removed. Please select a
                new portfolio in target saving.
              </div>
            )}
          <Table.Row textAlign="center">
            <Cell textAlign="left">Additional savings required per month</Cell>
            <Cell colSpan="2">
              {formatDollarAmount(savingResult[monthlySavingChosen])}
            </Cell>
          </Table.Row>
          <Table.Row textAlign="center">
            <Cell small padded textAlign="left">
              Likelihood of success
            </Cell>
            <Cell small colSpan="2">
              {this.renderSuccess('saving', savingResult[monthlySavingChosen])}
            </Cell>
          </Table.Row>
          <Table.Row textAlign="center">
            <Cell textAlign="left">Tax implications per year: Year 1</Cell>
            {savingResult.tax_implications[monthlySavingChosen]
              .expected_taxes !== null ? (
              <div>
                <Cell>
                  {formatDollarAmount(
                    savingResult.tax_implications[monthlySavingChosen]
                      .expected_taxes
                  )}
                </Cell>
                <Cell>
                  {formatDollarAmount(
                    savingResult.tax_implications[monthlySavingChosen]
                      .expected_taxes2
                  )}
                </Cell>
              </div>
            ) : (
              <Cell colSpan="2">
                <b
                  style={{ color: `${colors.red}`, cursor: 'pointer' }}
                  onClick={() => {
                    dispatch(
                      Planning.updateBlueprintData('target_saving', {
                        currentSubPage: 1,
                      })
                    );
                    history.push(
                      `/planning/blueprint/${match.params.id}/target_saving`
                    );
                  }}
                >
                  This is a new feature. Complete two additional questions in
                  Target Saving.
                </b>
              </Cell>
            )}
          </Table.Row>
        </Table.Body>
      </Table>
    );
  };

  renderSingleTax = () => {
    const { data, dispatch, partnerName, history, match } = this.props;
    const { targetWealth, targetSaving, savingResult } = data;
    const { modelType } = targetWealth || {};
    const { monthlySavingChosen, savings_portfolio } = targetSaving || {};
    return (
      <Table basic>
        <Table.Header>
          <Table.Row textAlign="center">
            <Table.HeaderCell width={10} />
            <Table.HeaderCell width={6} />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row textAlign="center">
            <Cell textAlign="left">Current savings</Cell>
            <Cell textAlign="right">{this.formatNum(targetSaving.init)}</Cell>
          </Table.Row>
          <Table.Row textAlign="center">
            <Cell textAlign="left">Pre-retirement portfolio</Cell>
            <Cell textAlign="right">
              {this.getPortfolio(savings_portfolio)}
            </Cell>
          </Table.Row>
          {this.portfolioIsDeleted(savings_portfolio, modelType) &&
            !isGlobeInvestor(partnerName) && (
              <div
                style={{
                  color: 'red',
                  width: '125%',
                  marginBottom: '1rem',
                }}
              >
                Your pre-retirement portfolio has been removed. Please select a
                new portfolio in target saving.
              </div>
            )}
          <Table.Row textAlign="center">
            <Cell textAlign="left">Additional savings required per month</Cell>
            <Cell textAlign="right">
              {this.formatNum(savingResult[monthlySavingChosen])}
            </Cell>
          </Table.Row>
          <Table.Row textAlign="center">
            <Cell padded small textAlign="left">
              Likelihood of success
            </Cell>
            <Cell small textAlign="right">
              {this.renderSuccess('saving', savingResult[monthlySavingChosen])}
            </Cell>
          </Table.Row>
          <Table.Row textAlign="center">
            <Cell textAlign="left">Tax implications per year: Year 1</Cell>
            {savingResult.tax_implications &&
            savingResult.tax_implications[monthlySavingChosen]
              .expected_taxes !== null ? (
              <Cell textAlign="right">
                {formatDollarAmount(
                  savingResult.tax_implications[monthlySavingChosen]
                    .expected_taxes
                )}
              </Cell>
            ) : (
              <b
                style={{ color: `${colors.red}`, cursor: 'pointer' }}
                onClick={() => {
                  dispatch(
                    Planning.updateBlueprintData('target_saving', {
                      currentSubPage: 1,
                    })
                  );
                  history.push(
                    `/planning/blueprint/${match.params.id}/target_saving`
                  );
                }}
              >
                This is a new feature. Complete two additional questions in
                Target Saving.
              </b>
            )}
          </Table.Row>
        </Table.Body>
      </Table>
    );
  };

  render() {
    const { isInputWarningOpen, isTargetWarningOpen, isSavingWarningOpen } =
      this.state;
    const { data, match, partnerName } = this.props;
    const {
      retirementBlueprint,
      targetWealth,
      targetSaving,
      wealthResult,
      savingResult,
    } = data;
    const { is_couple_plan } = retirementBlueprint || {};
    const isCouplePlan = is_couple_plan === 'true';
    const { personalDetails } = data;
    const { retAge } = personalDetails || {};
    const { targ, wealth_portfolio, modelType } = targetWealth || {};
    const { monthlySavingChosen } = targetSaving || {};

    return (
      <BlueprintContent>
        <CustomHeader colour>
          {data.name || isGlobeInvestor(partnerName)
            ? 'Retire Roadmap'
            : 'Retirement Blueprint'}
          {!isGlobeInvestor(partnerName) &&
          (isInputWarningOpen ||
            isTargetWarningOpen ||
            (isSavingWarningOpen && !this.isDisabled)) ? (
            <Popup
              trigger={
                <Icon
                  name="exclamation triangle"
                  className="unsaved-warn"
                  style={{
                    color: `${colors.red}`,
                    marginLeft: '0.5rem',
                    display: 'inline-block',
                  }}
                />
              }
              position="right center"
              content="Please note: your Blueprint currently has unsaved changes. To keep your information, please either save or print your Summary."
              wide
            />
          ) : null}
          <div className="subheader">{data.description}</div>
          <div className="subheader">
            {this.getDate()}&nbsp;
            <Popup
              trigger={
                <Icon
                  name="question circle outline"
                  style={{ fontSize: '1rem', transform: 'translateY(-0.7px)' }}
                />
              }
              position="right center"
              content={
                isGlobeInvestor(partnerName)
                  ? 'This is the as-of-date of the simulations. Consider recreating your Roadmap if your personal details change. Over time, your Target Wealth and Target Savings assumptions may also adjust as more portfolio return data becomes available in the simulations.'
                  : 'This is the as-of date of the simulations. You may wish to revisit the Blueprint at a later date as your personal details change. Note that in the future, your Target Wealth and Target Saving may also change with additional portfolio return history in the simulations.'
              }
              wide
            />
          </div>
        </CustomHeader>
        <MainContainer>
          <div className="dialogue-container">
            {targetSaving &&
              savingResult &&
              monthlySavingChosen &&
              savingResult[monthlySavingChosen] > 1 && (
                <Dialogue className="savings-dialogue">
                  <h4 className="dialog-title">Are You On Track?</h4>
                  <p>
                    By saving{' '}
                    <strong>
                      {formatDollarAmount(savingResult[monthlySavingChosen])}
                    </strong>{' '}
                    a month starting today, you are expected to reach your
                    retirement goal of{' '}
                    <strong>{formatDollarAmount(targ)}</strong> (in future
                    dollars) by age <strong>{retAge}</strong> with a{' '}
                    <strong>
                      {this.renderSuccess(
                        'saving',
                        savingResult[monthlySavingChosen]
                      )}
                    </strong>{' '}
                    success rate. You are on track if you are contributing at
                    least this amount per month to the portfolio you selected in
                    the plan.
                  </p>
                </Dialogue>
              )}{' '}
            {targetSaving &&
              savingResult &&
              monthlySavingChosen &&
              savingResult[monthlySavingChosen] < 1 && (
                <Dialogue className="savings-dialogue">
                  <h4 className="dialog-title">Congratulations!</h4>
                  <p>
                    Given your inputs and the options you chose in the current
                    plan, it seems that you are on track! With no additional
                    savings, you are expected to reach your retirement goal of{' '}
                    <strong>{formatDollarAmount(targ)}</strong> (in future
                    dollars) by age <strong>{retAge}</strong> with a{' '}
                    <strong>
                      {this.renderSuccess(
                        'saving',
                        savingResult[monthlySavingChosen]
                      )}
                    </strong>{' '}
                    success rate.
                  </p>
                </Dialogue>
              )}
            <Dialogue className="dialogue">
              <p>
                <Icon
                  name="lightbulb"
                  style={{ color: 'black', fontSize: '15px' }}
                />
                Now that you have a realistic expectation of your retirement
                plan, you can explore alternative scenarios using the{' '}
                <strong>EDIT</strong> buttons. For example:
                <li>What happens if I delay my retirement by 3 years?</li>
                <li>
                  What if I invest in a more aggressive/conservative portfolio?
                </li>
              </p>
            </Dialogue>
          </div>
        </MainContainer>

        <CustomHeader margin isGlobe={isGlobeInvestor(partnerName)}>
          Personal Details
          {this.renderLabel(
            'personal_details',
            'Personal Details',
            'personalDetails'
          )}
        </CustomHeader>

        {is_couple_plan === 'true' ? this.renderCouple() : this.renderSingle()}

        <CustomHeader margin isGlobe={isGlobeInvestor(partnerName)}>
          Target Wealth at Retirement
          {this.renderLabel('target_wealth', 'Target Wealth', 'targetWealth')}
        </CustomHeader>

        {targetWealth && targ && wealthResult ? (
          <Table basic>
            <Table.Header>
              <Table.Row textAlign="center">
                <Table.HeaderCell width={10} />
                <Table.HeaderCell width={4} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row textAlign="center">
                <Cell textAlign="left">Post retirement portfolio</Cell>
                <Cell
                  textAlign={!isCouplePlan && 'right'}
                  colSpan={isCouplePlan && 2}
                >
                  {this.getPortfolio(
                    targetWealth.wealth_portfolio || 'annuityRate',
                    modelType
                  )}
                </Cell>
              </Table.Row>

              <Table.Row textAlign="center">
                <Cell textAlign="left">
                  Target Wealth required to cover spending
                </Cell>
                <Cell
                  textAlign={!isCouplePlan && 'right'}
                  colSpan={isCouplePlan && 2}
                >
                  {formatDollarAmount(targ)}
                </Cell>
              </Table.Row>
              {wealth_portfolio !== null && (
                <Table.Row textAlign="center">
                  <Cell padded small textAlign="left">
                    Likelihood of success
                  </Cell>
                  <Cell
                    textAlign={!isCouplePlan && 'right'}
                    colSpan={isCouplePlan && 2}
                  >
                    {this.renderSuccess('wealth', targ)}
                  </Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        ) : (
          <Message icon error visible style={{ marginTop: '1rem' }}>
            <Icon name="warning sign" />
            <Message.Content>
              {this.renderContent('target wealth')}
            </Message.Content>
          </Message>
        )}
        <CustomHeader margin isGlobe={isGlobeInvestor(partnerName)}>
          Target Monthly Savings Starting Today
          {targetWealth &&
            targ &&
            this.renderLabel('target_saving', 'Target Saving', 'targetSaving')}
        </CustomHeader>

        {targetSaving && savingResult ? (
          isCouplePlan ? (
            this.renderCoupleTax()
          ) : (
            this.renderSingleTax()
          )
        ) : (
          <Message icon error visible style={{ marginTop: '1rem' }}>
            <Icon name="warning sign" />
            <Message.Content>
              {this.renderContent('monthly savings')}
            </Message.Content>
          </Message>
        )}
        {savingResult && monthlySavingChosen && (
          <MainContainer>
            <div className="conclusion-container">
              <Dialogue className="conclusion">
                <p>
                  <Icon
                    name="check circle"
                    style={{ color: 'black', fontSize: '15px' }}
                  />
                  The additional saving of{' '}
                  <strong>
                    {formatDollarAmount(savingResult[monthlySavingChosen])}
                  </strong>{' '}
                  is the amount you should add to your retirement investment
                  portfolio every month. Use up your contribution room in your
                  tax-sheltered, registered accounts first. Else, if you are
                  contributing all or part of the amount to a taxable account,
                  make sure you set aside money in your savings account each
                  year for tax purposes.
                </p>
              </Dialogue>
            </div>
          </MainContainer>
        )}
        <div
          className="save-modal-container"
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          <div
            className="save-container"
            onClick={this.handleToggleClick}
            style={{ width: 'fit-content', right: 'auto', left: 'auto' }}
          >
            <SaveModal
              name={data.name}
              description={data.description}
              id={match.params.id}
              data={data}
              isDisabled={this.isDisabled()}
            />
          </div>
        </div>
      </BlueprintContent>
    );
  }
}

Summary.propTypes = {
  match: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object,
  portfolio: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  cacheBlueprint: PropTypes.object,
  cacheInputChanged: PropTypes.bool,
  cachePDChanged: PropTypes.bool,
  cachePortfolioChanged: PropTypes.bool,
  cacheTargetChanged: PropTypes.bool,
  cacheSavingChanged: PropTypes.bool,
  cacheMSChanged: PropTypes.bool,
  cacheWealthChanged: PropTypes.bool,
  partnerName: PropTypes.string,
  scrollTo: PropTypes.object,
  user_region: PropTypes.string,
};

Summary.defaultProps = {
  history: {},
  cacheBlueprint: null,
  cacheInputChanged: false,
  cachePDChanged: false,
  cachePortfolioChanged: false,
  cacheTargetChanged: false,
  cacheSavingChanged: false,
  cacheMSChanged: false,
  cacheWealthChanged: false,
  partnerName: '',
  scrollTo: {},
  user_region: 'CA',
};

export default withRouter(
  connect((state) => ({
    portfolio: state.Portfolio,
    cacheBlueprint: state.Storage['cache-pwpa-blueprint'],
    cacheInputChanged: state.Storage.cacheInputChanged,
    cachePDChanged: state.Storage.cachePDChanged,
    cachePortfolioChanged: state.Storage.cachePortfolioChanged,
    cacheTargetChanged: state.Storage.cacheTargetChanged,
    cacheSavingChanged: state.Storage.cacheSavingChanged,
    cacheMSChanged: state.Storage.cacheMSChanged,
    cacheWealthChanged: state.Storage.cacheWealthChanged,
    partnerName: state.Storage['partner-name'],
    user_region: localStorage.getItem('user_region') || 'CA',
  }))(Summary)
);

const ListItem = styled(({ className, children, noBorder, small, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <List.Item className={className} {...rest}>
    {children}
  </List.Item>
))`
  position: relative;
  margin: ${(props) => (props.small ? '0 0 0 2.5rem' : '0')};
  padding: ${(props) => (props.small ? '4px 0' : '0')};
  border: ${(props) => (props.noBorder ? '0 !important' : 'initial')};

  .header {
    font-size: 1.2rem !important;
    font-weight: 400 !important;

    &.small {
      font-size: 1rem !important;
    }
  }

  .value {
    position: absolute;
    top: 8px;
    right: 0;
    font-size: 1.4rem;

    &.small {
      font-size: 1.1rem;
    }
  }
`;

const CustomHeader = styled.div`
  position: relative;
  margin: ${(props) => (props.margin ? '3rem 0 0.5rem 0' : '1rem 0 0.5rem 0')};
  font-size: 20px;
  font-weight: 700;
  color: ${(props) => (props.colour ? 'rgba(0,0,0,0.6)' : 'black')};

  .label {
    position: absolute !important;
    top: -3px !important;
    right: 0;
    margin-left: ${(props) =>
      props.isGlobe ? '11px !important' : '28px !important'};
    background: ${colors.teal};
    cursor: pointer;
    width: 11rem;
  }

  .subheader {
    font-size: 1.1rem;
    color: rgba(0, 0, 0, 0.6);
  }
`;

const Cell = styled(Table.Cell)`
  font-size: ${(props) => (props.small ? '1rem' : '1.2rem')};
  padding-left: ${(props) => (props.padded ? '1.5rem !important' : '0')};
  border: ${(props) => (props.border ? '0 !important' : 'inherit')};
  text-align: ${(props) => props.textAlign || 'center'};
`;

const BlueprintContent = styled.div`
  margin: 1rem 1.5rem;
  padding: 1rem 1.5rem;
`;

const MainContainer = styled.div`
  .dialogue {
    background-color: #b7c1fe;
    margin: 1em !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
  }
  .dialogue-title {
    right: auto;
    left: auto;
  }
  .savings-dialogue {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #cbe8ee;
    margin: 1em !important;
  }
  .dialogue-container {
    display: flex;
    text-align: justify;
  }
  .conclusion-container {
    display: flex;
    justify-content: center;
  }
  .conclusion {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: justify;
    max-width: 50rem;
    height: auto;
  }
`;

const Dialogue = styled(Segment)`
  background: ${colors.lightBlue};
  margin-top: 1em;
`;
