/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NavLink, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import { colors } from 'utils/colors';
import { Planning } from 'actions';
import { NotFound } from 'components';

import Overview from './Overview';
import PersonalDetails from './PersonalDetails';
import TargetWealth from './TargetWealth';
import TargetSaving from './TargetSaving';
import Summary from './Summary';
import PDF from './PDF';
import { isGlobeInvestor } from '../../../../utils/helpers';

class Blueprint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tsClickable: false,
    };
    this.blueprintHeader = null;
  }

  componentWillMount() {
    const { dispatch, match, cacheBlueprint } = this.props;
    const { id } = match.params;

    return id === 'new'
      ? null
      : !cacheBlueprint
      ? dispatch(Planning.fetchBlueprintById(id))
      : null;
  }

  componentDidUpdate(prevProps) {
    const { blueprint } = this.props;
    if (prevProps.blueprint !== blueprint) {
      this.calcTargetSavingsClickable();
    }
  }

  calcProgressWidth = (sectionName) => {
    const { blueprint, partnerName } = this.props;
    const sectionPages = {
      retirementBlueprint: isGlobeInvestor(partnerName) ? 1 : 2,
      personalDetails: 7,
      targetWealth: 1,
      targetSaving: 1,
      summary: 1,
    };

    let { currentSubPage } = blueprint[sectionName];

    // For the Globe; they want to skip the intro page
    if (sectionName === 'retirementBlueprint' && isGlobeInvestor(partnerName)) {
      currentSubPage -= 1;
    }

    if (!currentSubPage) return 0;
    const currentSectionPages = sectionPages[sectionName];
    return Math.min(100, 100 * ((currentSubPage - 1) / currentSectionPages));
  };

  // Calculates if the target savings tab should be clickable based on if previous sections
  // have been edited. Necessary now that target savings information is kept even after changes.
  calcTargetSavingsClickable = () => {
    const { cacheInputChanged, cachePortfolioChanged, cacheTargetChanged } =
      this.props;

    const value = !(
      cacheInputChanged ||
      cachePortfolioChanged ||
      cacheTargetChanged
    );

    this.setState({ tsClickable: value });
  };

  // handles taking users to the first page of a section when
  // they click on its corresponding tab
  handleSectionClick = (sectionName) => {
    const { blueprint, dispatch, partnerName } = this.props;
    if (blueprint[sectionName]) {
      if (
        isGlobeInvestor(partnerName) &&
        sectionName === 'retirementBlueprint'
      ) {
        return dispatch(
          Planning.updateBlueprintData('retirementBlueprint', {
            currentSubPage: 2,
          })
        );
      }
      return dispatch(
        Planning.updateBlueprintData(sectionName, {
          currentSubPage: 1,
        })
      );
    }

    return null;
  };

  render() {
    const { blueprint, match, history, partnerName } = this.props;
    const { tsClickable } = this.state;

    return !blueprint ? null : (
      <div style={{ padding: isGlobeInvestor(partnerName) ? '1rem' : '' }}>
        <Container
          partnerName={partnerName}
          noMargin={history.location.pathname.includes('pdf')}
        >
          <div
            id="blueprint-header"
            ref={(el) => (this.blueprintHeader = el)}
            style={{
              position: 'relative',
              display: `${
                history.location.pathname.includes('pdf') ? 'none' : 'block'
              }`,
            }}
          >
            {blueprint.retirementBlueprint ? (
              <LinkTab
                progresswidth={this.calcProgressWidth('retirementBlueprint')}
                to={`/planning/blueprint/${match.params.id}/overview`}
                onClick={() => this.handleSectionClick('retirementBlueprint')}
                isGlobe={isGlobeInvestor(partnerName)}
              >
                {isGlobeInvestor(partnerName)
                  ? '1. Start'
                  : '1. Retirement Blueprint'}
              </LinkTab>
            ) : (
              <Tab>
                {isGlobeInvestor(partnerName)
                  ? '1. Start'
                  : '1. Retirement Blueprint'}
              </Tab>
            )}
            {blueprint.personalDetails ? (
              <LinkTab
                to={`/planning/blueprint/${match.params.id}/personal_details`}
                progresswidth={this.calcProgressWidth('personalDetails')}
                onClick={() => this.handleSectionClick('personalDetails')}
                isGlobe={isGlobeInvestor(partnerName)}
              >
                2. Personal Details
              </LinkTab>
            ) : (
              <Tab>2. Personal Details</Tab>
            )}
            {blueprint.targetWealth ? (
              <LinkTab
                to={`/planning/blueprint/${match.params.id}/target_wealth`}
                progresswidth={this.calcProgressWidth('targetWealth')}
                onClick={() => this.handleSectionClick('targetWealth')}
                isGlobe={isGlobeInvestor(partnerName)}
              >
                3. Target Wealth
              </LinkTab>
            ) : (
              <Tab>3. Target Wealth</Tab>
            )}
            {blueprint.targetSaving && tsClickable ? (
              <LinkTab
                to={`/planning/blueprint/${match.params.id}/target_saving`}
                progresswidth={this.calcProgressWidth('targetSaving')}
                onClick={() => this.handleSectionClick('targetSaving')}
                isGlobe={isGlobeInvestor(partnerName)}
              >
                4. Target Saving
              </LinkTab>
            ) : (
              <Tab>4. Target Saving</Tab>
            )}
            {blueprint.summary ? (
              <LinkTab
                to={`/planning/blueprint/${match.params.id}/summary`}
                progresswidth={blueprint.summary.currentSubPage ? 100 : 0}
                onClick={() => this.handleSectionClick('summary')}
                isGlobe={isGlobeInvestor(partnerName)}
              >
                5. Summary
              </LinkTab>
            ) : (
              <Tab>5. Summary</Tab>
            )}
          </div>

          <Switch>
            <Route
              path="/planning/blueprint/:id/overview"
              render={() => <Overview data={blueprint} />}
            />
            <Route
              path="/planning/blueprint/:id/personal_details"
              render={() => (
                <PersonalDetails
                  scrollTo={this.blueprintHeader}
                  data={blueprint}
                />
              )}
            />
            <Route
              path="/planning/blueprint/:id/target_wealth"
              render={() => (
                <TargetWealth
                  scrollTo={this.blueprintHeader}
                  data={blueprint}
                />
              )}
            />
            <Route
              path="/planning/blueprint/:id/target_saving"
              render={() => (
                <TargetSaving
                  scrollTo={this.blueprintHeader}
                  data={blueprint}
                />
              )}
            />
            <Route
              path="/planning/blueprint/:id/summary"
              render={() => (
                <Summary scrollTo={this.blueprintHeader} data={blueprint} />
              )}
            />
            <Route
              path="/planning/blueprint/:id/pdf"
              render={() => <PDF data={blueprint} />}
            />
            <Route component={NotFound} />
          </Switch>
        </Container>
      </div>
    );
  }
}

Blueprint.propTypes = {
  history: PropTypes.object,
  blueprint: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  cacheBlueprint: PropTypes.object,
  partnerName: PropTypes.string,
  cacheInputChanged: PropTypes.bool,
  cachePortfolioChanged: PropTypes.bool,
  cacheTargetChanged: PropTypes.bool,
};

Blueprint.defaultProps = {
  blueprint: null,
  cacheBlueprint: null,
  history: {},
  partnerName: '',
  cacheInputChanged: false,
  cachePortfolioChanged: false,
  cacheTargetChanged: false,
};

export default connect((state) => ({
  blueprint: state.Planning.blueprint,
  cacheBlueprint: state.Storage['cache-pwpa-blueprint'],
  partnerName: state.Storage['partner-name'],
  cacheInputChanged: state.Storage.cacheInputChanged,
  cachePortfolioChanged: state.Storage.cachePortfolioChanged,
  cacheTargetChanged: state.Storage.cacheTargetChanged,
}))(Blueprint);

const Container = styled.div`
  margin: 1rem;
  border-radius: ${(props) => (isGlobeInvestor(props.partnerName) ? 0 : '8px')};
  background-color: white;
  min-width: 880px;
  border: ${(props) =>
    isGlobeInvestor(props.partnerName)
      ? '1px solid rgba(0, 0, 0, 0.2)'
      : 'none'};
  #blueprint-header {
    scroll-margin-top: ${(props) =>
      isGlobeInvestor(props.partnerName) ? '10px' : '70px'};
  }
`;

const Tab = styled(({ className, children, ...rest }) => (
  <div className={className} {...rest}>
    {children}
  </div>
))`
  display: inline-block;
  padding: 1rem 0;
  width: calc(100% / 5);
  font-size: 1rem;
  position: relative;
  color: rgba(0, 0, 0, 0.25);

  text-align: center;
  border-left: 1px solid rgb(236, 236, 240);
  border-bottom: 1px solid rgb(236, 236, 240);
`;

const LinkTab = styled(({ className, children, isGlobe, ...rest }) => (
  <NavLink className={className} {...rest}>
    {children}
  </NavLink>
))`
  display: inline-block;
  padding: 1rem 0;
  width: calc(100% / 5);
  font-size: 1rem;
  position: relative;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
  border-left: 1px solid rgb(236, 236, 240);
  border-bottom: 1px solid rgb(236, 236, 240);

  &:first-child {
    border-left: 0;
    border-top-left-radius: ${(props) => (props.isGlobe ? 0 : '8px')};

    &:after {
      border-top-left-radius: ${(props) => (props.isGlobe ? 0 : '10px')};
    }
  }

  &:last-child {
    border-top-left-radius: ${(props) => (props.isGlobe ? 0 : '8px')};

    &:after {
      border-top-right-radius: ${(props) => (props.isGlobe ? 0 : '10px')};
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 5px;
    width: ${(props) => props.progresswidth}%;
    background: ${(props) => (props.isGlobe ? colors.red : colors.orange)};
  }

  &.active {
    font-weight: 800;
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-thickness: 2px;
    text-decoration-color: ${colors.grey};
  }

  &:hover {
    font-weight: 700;
    color: rgba(0, 0, 0, 0.8);
  }
`;
